import { memo, useCallback, useState } from "react";

import { Button, Col, Input, Row, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

import { FormItem, useForm } from "@app/components/Common/atoms/Form/Form";
import FormModal from "@app/components/Common/atoms/FormModal/FormModal";

import useShowModal from "@app/hooks/useShowModal";

import styles from "./ToolFormModal.module.scss";
import { useAppSelector } from "@app/store/store";
import { ITool } from "@app/types/common.types";
import TextArea from "antd/lib/input/TextArea";
import { TOOLS_CATEGORY_OPTIONS } from "@app/constants/common";
import { logger } from "@app/utils/logger";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import { privateAccountsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";

export const CUSTOM_ACTION_CREATE = "create_tool";

interface ToolFormModalProps {
  okText?: string;
  onConfirm: (data: ITool, callback: () => void) => void;
  loading?: boolean;
  initialValues?: Record<string, string> | ITool;
  errors?: Record<string, string>;
  onClose: () => void;
}

const ToolFormModal = ({
  initialValues,
  errors,
  onConfirm,
  onClose,
  loading,
}: ToolFormModalProps) => {
  const { isShowModal, closeModal } = useShowModal({
    customActionType: CUSTOM_ACTION_CREATE,
  });
  const { t } = useTranslation();

  const [isFetching, setFetchingStatus] = useState<boolean>(false);
  const { modal } = useAppSelector(state => state.ui);
  const [form] = useForm();

  const handleClose = useCallback(() => {
    form.resetFields();
    closeModal();
    onClose();
  }, [closeModal, form]);

  const handleFinish = async (values: ITool) => {
    onConfirm(
      { ...values, ...(modal?.entryId && { id: modal.entryId }) },
      handleClose
    );
  };

  // eslint-disable-next-line
  const handleInputURL = async (e: Event) => {
    const target = e.target as HTMLInputElement;
    setFetchingStatus(true);

    try {
      const res = await privateAccountsApi.opengraphDataGet({
        url: target.value,
      });
      form.setFieldsValue({
        title: res.data.title,
        description: res.data.description,
      });
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setFetchingStatus(false);
    }
  };

  return (
    <FormModal
      form={form}
      title={modal && !modal.entryId ? t("Create Tool") : t("Edit Tool")}
      visible={isShowModal}
      onClose={handleClose}
      values={initialValues as Record<string, string>}
      errors={errors}
      onFinish={handleFinish}
      destroyOnClose
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={handleClose}>{t("default.cancelTitle")}</Button>
              <Button
                type="primary"
                disabled={loading}
                className={styles.submitButton}
                danger
                htmlType="submit"
                loading={loading}
              >
                {t("default.saveTitle")}
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <FormItem
            name="url"
            label={t("API Tool URL")}
            rules={[
              {
                required: true,
                pattern:
                  // eslint-disable-next-line
                  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                message: t("Invalid URL format"),
              },
            ]}
          >
            <Input
              placeholder={t("Input URL")}
              // eslint-disable-next-line
              // @ts-ignore
              onBlur={handleInputURL}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name="title" label={t("Tile")}>
            <Input placeholder={t("Input title")} />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem
            name="category"
            label={t("Category")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <Select
              className={styles.category}
              placeholder="Category"
              options={TOOLS_CATEGORY_OPTIONS}
            />
          </FormItem>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <FormItem name="description" label={t("Description")}>
            <TextArea rows={4} placeholder={t("default.description")} />
          </FormItem>
        </Col>
      </Row>
      {isFetching && (
        <div className={styles.fetchingForm}>
          <LoadingSpinner />
        </div>
      )}
    </FormModal>
  );
};

export default memo(ToolFormModal);
