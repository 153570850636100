import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";

import store from "@app/store/store";

import "@app/features/localization/localization";
import App from "@app/containers/App/App";
import { HelmetProvider } from "react-helmet-async";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";

import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "@app/store/store";

import "./index.scss";

const render = () => {
  const container = document.getElementById("root");
  const root = createRoot(container!);
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18n}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </I18nextProvider>
      </PersistGate>
    </Provider>
  );
};

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./containers/App/App", render);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
