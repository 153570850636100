import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { clearAllTokens } from "../../../helpers/auth.helpers";

export const AUTH_FEATURE_KEY = "auth";
interface UserAttributesPrivateDef {
  name: string;
}
interface UserPrivateDef {
  attributes: UserAttributesPrivateDef;
}
interface SliceState {
  user: UserPrivateDef | null;
  error: boolean;
  loading: boolean;
}

const initialState: SliceState = {
  user: null,
  error: false,
  loading: false,
};

const authSlice = createSlice({
  name: AUTH_FEATURE_KEY,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserPrivateDef>) => {
      state.user = action.payload;
    },
    clearUser: state => {
      state.user = null;
      clearAllTokens();
    },
  },
});

export const { clearUser, setUser } = authSlice.actions;

export const authReducer = authSlice.reducer;
