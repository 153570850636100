import React, { memo, useCallback } from "react";

import { Button, Col, Input, Row, Space } from "antd";
import { useTranslation } from "react-i18next";

import { FormItem, useForm } from "@app/components/Common/atoms/Form/Form";
import FormModal from "@app/components/Common/atoms/FormModal/FormModal";

import useShowModal from "@app/hooks/useShowModal";

import styles from "./AccountFormModal.module.scss";
import TextArea from "antd/lib/input/TextArea";
import { convertToSlug } from "@app/helpers/util.helper";
import { useAppSelector } from "@app/store/store";
import { IAccount } from "@app/features/profile/profile.types";

export const CUSTOM_ACTION_CREATE = "create";

interface AccountFormModalProps {
  onConfirm: (data: DataPayloadDef, callback: () => void) => void;
  loading?: boolean;
  errors?: Record<string, string>;
  initialValues?: Record<string, string> | IAccount;
  onClose: () => void;
}

export interface DataPayloadDef {
  name: string;
  title: string;
  description: string;
  id?: string;
}

const AccountFormModal = ({
  onConfirm,
  loading,
  errors,
  initialValues,
  onClose,
}: AccountFormModalProps) => {
  const { isShowModal, closeModal } = useShowModal({
    customActionType: CUSTOM_ACTION_CREATE,
  });
  const { t } = useTranslation();
  const { modal } = useAppSelector(state => state.ui);

  const [form] = useForm();

  const handleClose = useCallback(() => {
    form.resetFields();
    closeModal();
    if (onClose) onClose();
  }, [closeModal, form]);

  const handleFinish = async (values: DataPayloadDef) => {
    onConfirm(
      { ...values, ...(modal?.entryId && { id: modal.entryId }) },
      handleClose
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (form.getFieldValue("name") === "") {
      form.setFieldValue("name", convertToSlug(value));
    }
  };

  return (
    <FormModal
      form={form}
      title={
        modal && !modal.entryId
          ? t("profile.AddAccountTitle")
          : t("profile.EditAccountTitle")
      }
      visible={isShowModal}
      onClose={handleClose}
      values={initialValues as Record<string, string>}
      onFinish={handleFinish}
      destroyOnClose
      errors={errors}
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={handleClose}>{t("default.cancelTitle")}</Button>
              <Button
                type="primary"
                disabled={loading}
                className={styles.submitButton}
                danger
                htmlType="submit"
                loading={loading}
              >
                {t("default.saveTitle")}
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <Row gutter={20}>
        <Col span={12}>
          <FormItem
            name="title"
            label={t("Title")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <Input onBlur={handleChange} />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem
            name="name"
            label={t("Name")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
              {
                pattern: new RegExp(/^[a-z0-9-_]*$/),
                message:
                  "Name can only contain lowercase letters, numbers, hyphens (-), and underscores (_)",
              },
            ]}
          >
            <Input />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={24} className="gutter-row">
          <FormItem
            name="description"
            label={t("default.description")}
            rules={[
              {
                required: true,
                message: t("This field is required"),
              },
            ]}
          >
            <TextArea rows={6} placeholder={t("default.description")} />
          </FormItem>
        </Col>
      </Row>
    </FormModal>
  );
};

export default memo(AccountFormModal);
