import { Collapse } from "antd";
import cx from "classnames";

import {
  Operations,
  OperationsItemType,
  OperationsType,
} from "@app/types/api.types";

import "./ApiOperation.scss";
import { useMount } from "react-use";
import { useState } from "react";

const ApiOperation = ({
  operations,
  children,
}: {
  operations: Operations;
  children: React.ReactNode;
}) => {
  const [groups, setGroupByTags] = useState<OperationsType<
    Array<OperationsItemType>
  > | null>(null);

  const groupByTags = () => {
    return operations.reduce(
      (grouped: OperationsType<Array<OperationsItemType>>, item) => {
        if (!grouped["noTags"]) {
          grouped["noTags"] = [];
        }
        if (!item.tags || item.tags?.length === 0) {
          grouped["noTags"].push(item);
        }

        item.tags?.forEach((tag: string) => {
          grouped[tag] = grouped[tag] || [];
          grouped[tag].push(item);
        });
        return grouped;
      },
      {}
    );

    // Return the result as an array with noTags first
    // const resultArray = [
    //   { key: "noTags", items: grouped["noTags"] || [] },
    //   ...Object.entries(grouped)
    //     .filter(([key]) => key !== "noTags")
    //     .map(([key, items]) => ({ key, items }))
    // ];
  };

  useMount(() => {
    const groupedOperations = groupByTags();
    setGroupByTags(groupedOperations);
  });

  return (
    <div>
      {operations &&
        groups &&
        Object.keys(groups).map(tag => {
          const operations = groups[tag];
          return (
            <div>
              {tag !== "noTags" && <h6 className="tag-title">{tag}</h6>}
              <Collapse className="panelGroup" expandIconPosition="end">
                {operations.map(operation => (
                  <Collapse.Panel
                    header={
                      <span>
                        <b>{operation.httpMethod}</b> {operation.path}
                      </span>
                    }
                    key={operation.id}
                    className={cx("panel", operation.httpMethod.toLowerCase())}
                  >
                    {children}
                  </Collapse.Panel>
                ))}
              </Collapse>
            </div>
          );
        })}
    </div>
  );
};
export default ApiOperation;
