/* tslint:disable */
/* eslint-disable */
/**
 * Apigale RESTful API
 * **Apigale. API Service Blueprint Platform.** </br> It\'s an API sharing and collaboration platform, available for API **Providers** and **Consumers**.</br></br> For the **API Providers** it allows to:</br> - create a Catalog of API Specifications</br> - share APIs publicly or with specific Consumers</br> - navigate to source code, CI/CD workflows, deployments, logs and many more.</br></br> For the **API Consumers** it allows to:</br> - navigate the Catalog of API Specifications</br> - access API Documentation, view API Journeys, get support</br> - view source code, CI/CD workflows, deployments, logs and many more (subject to API Provider\'s permission levels set).</br></br> You can find out more about Apigale at [https://apigale.com](https://apigale.com). 
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: apiteam@apigale.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountIdentifierObjectAllOfDef
 */
export interface AccountIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectAllOfDef
     */
    type?: AccountIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountIdentifierObjectAllOfDefTypeEnum {
    PRIVATE_ACCOUNTS = 'private-accounts'
}

/**
 * 
 * @export
 * @interface AccountIdentifierObjectDef
 */
export interface AccountIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectDef
     */
    type?: AccountIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccountIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum AccountIdentifierObjectDefTypeEnum {
    PRIVATE_ACCOUNTS = 'private-accounts'
}

/**
 * 
 * @export
 * @interface BaseObjectAllOfDef
 */
export interface BaseObjectAllOfDef {
    /**
     * 
     * @type {BaseObjectAttributesDef}
     * @memberof BaseObjectAllOfDef
     */
    attributes: BaseObjectAttributesDef;
    /**
     * 
     * @type {object}
     * @memberof BaseObjectAllOfDef
     */
    relationships: object;
}
/**
 * 
 * @export
 * @interface BaseObjectAttributesDef
 */
export interface BaseObjectAttributesDef {
    /**
     * Object name. Can be used instread of `id` to uniquely identify the object within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    name: string;
    /**
     * Object title, or customer-facing name; to provide the full name of the object.
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    title?: string | null;
    /**
     * Object description; to provide a detailed information about the object.
     * @type {string}
     * @memberof BaseObjectAttributesDef
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface BaseObjectDef
 */
export interface BaseObjectDef {
    /**
     * 
     * @type {BaseObjectAttributesDef}
     * @memberof BaseObjectDef
     */
    attributes: BaseObjectAttributesDef;
    /**
     * 
     * @type {object}
     * @memberof BaseObjectDef
     */
    relationships: object;
}
/**
 * 
 * @export
 * @interface BasicErrorObjectDef
 */
export interface BasicErrorObjectDef {
    /**
     * 
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof BasicErrorObjectDef
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof BasicErrorObjectDef
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof BasicErrorObjectDef
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface CategoryAllOfDef
 */
export interface CategoryAllOfDef {
    /**
     * 
     * @type {CategoryAttributesDef}
     * @memberof CategoryAllOfDef
     */
    attributes?: CategoryAttributesDef;
}
/**
 * 
 * @export
 * @interface CategoryAttributesDef
 */
export interface CategoryAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryAttributesDef
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CategoryBodyDef
 */
export interface CategoryBodyDef {
    /**
     * 
     * @type {CategoryDef}
     * @memberof CategoryBodyDef
     */
    data?: CategoryDef;
}
/**
 * 
 * @export
 * @interface CategoryDef
 */
export interface CategoryDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryDef
     */
    type?: CategoryDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryDef
     */
    id?: string;
    /**
     * 
     * @type {CategoryAttributesDef}
     * @memberof CategoryDef
     */
    attributes?: CategoryAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryIdentifierObjectAllOfDef
 */
export interface CategoryIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectAllOfDef
     */
    type?: CategoryIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryIdentifierObjectAllOfDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryIdentifierObjectDef
 */
export interface CategoryIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectDef
     */
    type?: CategoryIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CategoryIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum CategoryIdentifierObjectDefTypeEnum {
    CATEGORIES = 'categories'
}

/**
 * 
 * @export
 * @interface CategoryListResponseBodyAllOfDef
 */
export interface CategoryListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<CategoryDef>}
     * @memberof CategoryListResponseBodyAllOfDef
     */
    data?: Array<CategoryDef>;
}
/**
 * 
 * @export
 * @interface CategoryListResponseBodyDef
 */
export interface CategoryListResponseBodyDef {
    /**
     * 
     * @type {Array<CategoryDef>}
     * @memberof CategoryListResponseBodyDef
     */
    data?: Array<CategoryDef>;
}
/**
 * 
 * @export
 * @interface CategoryResponseBodyDef
 */
export interface CategoryResponseBodyDef {
    /**
     * 
     * @type {CategoryDef}
     * @memberof CategoryResponseBodyDef
     */
    data?: CategoryDef;
}
/**
 * 
 * @export
 * @interface Error400Def
 */
export interface Error400Def {
    /**
     * 
     * @type {string}
     * @memberof Error400Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error400Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error400Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error400Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error400Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error400Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error401Def
 */
export interface Error401Def {
    /**
     * 
     * @type {string}
     * @memberof Error401Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error401Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error401Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error401Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error401Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error401Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error403Def
 */
export interface Error403Def {
    /**
     * 
     * @type {string}
     * @memberof Error403Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error403Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error403Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error403Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error403Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error403Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error404Def
 */
export interface Error404Def {
    /**
     * 
     * @type {string}
     * @memberof Error404Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error404Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error404Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error404Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error404Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error404Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error406Def
 */
export interface Error406Def {
    /**
     * 
     * @type {string}
     * @memberof Error406Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error406Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error406Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error406Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error406Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error406Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error409Def
 */
export interface Error409Def {
    /**
     * 
     * @type {string}
     * @memberof Error409Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error409Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error409Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error409Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error409Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error409Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error411Def
 */
export interface Error411Def {
    /**
     * 
     * @type {string}
     * @memberof Error411Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error411Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error411Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error411Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error411Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error411Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error413Def
 */
export interface Error413Def {
    /**
     * 
     * @type {string}
     * @memberof Error413Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error413Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error413Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error413Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error413Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error413Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error415Def
 */
export interface Error415Def {
    /**
     * 
     * @type {string}
     * @memberof Error415Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error415Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error415Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error415Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error415Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error415Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error422Def
 */
export interface Error422Def {
    /**
     * 
     * @type {string}
     * @memberof Error422Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error422Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error422Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error422Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error422Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error422Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface Error429Def
 */
export interface Error429Def {
    /**
     * 
     * @type {string}
     * @memberof Error429Def
     */
    id?: string;
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     * @type {number}
     * @memberof Error429Def
     */
    status?: number;
    /**
     * An application-specific error code, expressed as a string value.
     * @type {number}
     * @memberof Error429Def
     */
    code?: number;
    /**
     * A short, human-readable summary of the problem.
     * @type {string}
     * @memberof Error429Def
     */
    title?: string;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof Error429Def
     */
    detail?: string;
    /**
     * An object containing references to the source of the error.
     * @type {string}
     * @memberof Error429Def
     */
    source?: string;
}
/**
 * 
 * @export
 * @interface ErrorObjectsDef
 */
export interface ErrorObjectsDef {
    /**
     * 
     * @type {Array<Error400Def | Error401Def | Error403Def | Error404Def | Error409Def | Error422Def | Error429Def>}
     * @memberof ErrorObjectsDef
     */
    errors?: Array<Error400Def | Error401Def | Error403Def | Error404Def | Error409Def | Error422Def | Error429Def>;
}
/**
 * 
 * @export
 * @interface InlineObjectDef
 */
export interface InlineObjectDef {
    /**
     * 
     * @type {object}
     * @memberof InlineObjectDef
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface MediaDef
 */
export interface MediaDef {
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaDef
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface MediaResponseBodyDef
 */
export interface MediaResponseBodyDef {
    /**
     * 
     * @type {MediaDef}
     * @memberof MediaResponseBodyDef
     */
    data?: MediaDef;
}
/**
 * 
 * @export
 * @interface PaginationLinksDef
 */
export interface PaginationLinksDef {
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    next?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    prev?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof PaginationLinksDef
     */
    last?: string;
}
/**
 * 
 * @export
 * @interface PaginationMetaDef
 */
export interface PaginationMetaDef {
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    from?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    to?: number;
    /**
     * 
     * @type {string}
     * @memberof PaginationMetaDef
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    currentPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    lastPage?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDef
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface PrivateAccountAllOfDef
 */
export interface PrivateAccountAllOfDef {
    /**
     * 
     * @type {PrivateAccountAttributesDef}
     * @memberof PrivateAccountAllOfDef
     */
    attributes?: PrivateAccountAttributesDef;
}
/**
 * 
 * @export
 * @interface PrivateAccountAttributesDef
 */
export interface PrivateAccountAttributesDef {
    /**
     * Account name. Can be used instead of `id` to uniquely identify the Account. </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    name: string;
    /**
     * Account title, or customer-facing name; to provide the full name of the Account.
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    title?: string;
    /**
     * Account description; to provide a detailed information about the Account.
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateAccountBodyDef
 */
export interface PrivateAccountBodyDef {
    /**
     * 
     * @type {object}
     * @memberof PrivateAccountBodyDef
     */
    data?: object;
}
/**
 * 
 * @export
 * @interface PrivateAccountDef
 */
export interface PrivateAccountDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountDef
     */
    type?: PrivateAccountDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateAccountDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateAccountAttributesDef}
     * @memberof PrivateAccountDef
     */
    attributes?: PrivateAccountAttributesDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateAccountDefTypeEnum {
    PRIVATE_ACCOUNTS = 'private-accounts'
}

/**
 * 
 * @export
 * @interface PrivateAccountRelationshipsDef
 */
export interface PrivateAccountRelationshipsDef {
    /**
     * 
     * @type {PrivateAccountRelationshipsOwnerUsersDef}
     * @memberof PrivateAccountRelationshipsDef
     */
    ownerUsers?: PrivateAccountRelationshipsOwnerUsersDef;
    /**
     * 
     * @type {PrivateAccountRelationshipsOwnerUsersDef}
     * @memberof PrivateAccountRelationshipsDef
     */
    maintainerUsers?: PrivateAccountRelationshipsOwnerUsersDef;
}
/**
 * 
 * @export
 * @interface PrivateAccountRelationshipsOwnerUsersDef
 */
export interface PrivateAccountRelationshipsOwnerUsersDef {
    /**
     * 
     * @type {Array<UserIdentifierObjectDef>}
     * @memberof PrivateAccountRelationshipsOwnerUsersDef
     */
    data?: Array<UserIdentifierObjectDef>;
}
/**
 * 
 * @export
 * @interface PrivateAccountResponseBodyDef
 */
export interface PrivateAccountResponseBodyDef {
    /**
     * 
     * @type {AccountIdentifierObjectDef & object}
     * @memberof PrivateAccountResponseBodyDef
     */
    data?: AccountIdentifierObjectDef & object;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationAllOfDef
 */
export interface PrivateServiceSpecificationAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationAllOfDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationAllOfDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationAllOfDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationAllOfDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationAttributesDef
 */
export interface PrivateServiceSpecificationApiOperationAttributesDef {
    /**
     * API Operation name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`/service-specification). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    name?: string;
    /**
     * API Operation description; to provide a detailed information about the API Endpoint.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiOperationHttpMethodEnum}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    httpMethod: ServiceSpecificationApiOperationHttpMethodEnum;
    /**
     * Path of the API Operation.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    path: string;
    /**
     * 
     * @type {ServiceSpecificationApiOperationTagsDef}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    tags?: ServiceSpecificationApiOperationTagsDef | null;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    xCoordinate?: number | null;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    yCoordinate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationBodyDef
 */
export interface PrivateServiceSpecificationApiOperationBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationBodyDef
     */
    data: PrivateServiceSpecificationApiOperationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationDef
 */
export interface PrivateServiceSpecificationApiOperationDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    type?: PrivateServiceSpecificationApiOperationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationIdentifierObjectDef
 */
export interface PrivateServiceSpecificationApiOperationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkAllOfDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkAllOfDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkAttributesDef
 */
export interface PrivateServiceSpecificationApiOperationLinkAttributesDef {
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PrivateServiceSpecificationApiOperationLinkAttributesDef
     */
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PrivateServiceSpecificationApiOperationLinkAttributesDef
     */
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkBodyDef
     */
    data: PrivateServiceSpecificationApiOperationLinkDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkDef
 */
export interface PrivateServiceSpecificationApiOperationLinkDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>}
     * @memberof PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationLinkUnidentifiedDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkRelationshipsDef
 */
export interface PrivateServiceSpecificationApiOperationLinkRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsDef
     */
    outwardApiOperation: PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsDef
     */
    inwardApiOperation: PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
 */
export interface PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
     */
    data?: PrivateServiceSpecificationApiOperationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationLinkResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
 */
export interface PrivateServiceSpecificationApiOperationLinkUnidentifiedDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    attributes?: PrivateServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedDef
     */
    relationships?: PrivateServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkUnidentifiedDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef
 */
export interface PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDef
     */
    type?: PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDefTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationLinkUnidentifiedObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATION_LINKS = 'service-specification-api-operation-links'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationDef>}
     * @memberof PrivateServiceSpecificationApiOperationListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationListResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiOperationDef>}
     * @memberof PrivateServiceSpecificationApiOperationListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationPatchRequestBodyDef
 */
export interface PrivateServiceSpecificationApiOperationPatchRequestBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationIdentifierObjectDef & object}
     * @memberof PrivateServiceSpecificationApiOperationPatchRequestBodyDef
     */
    data?: PrivateServiceSpecificationApiOperationIdentifierObjectDef & object;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationRelationshipsDef
 */
export interface PrivateServiceSpecificationApiOperationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationApiOperationRelationshipsDef
     */
    serviceSpecification: PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef
 */
export interface PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef {
    /**
     * 
     * @type {PrivateServiceSpecificationIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef
     */
    data?: PrivateServiceSpecificationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiOperationResponseBodyDef
 */
export interface PrivateServiceSpecificationApiOperationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    type?: PrivateServiceSpecificationApiOperationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationAttributesDef}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiOperationResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiOperationResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_OPERATIONS = 'service-specification-api-operations'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolAllOfDef
 */
export interface PrivateServiceSpecificationApiToolAllOfDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolAttributesDef}
     * @memberof PrivateServiceSpecificationApiToolAllOfDef
     */
    attributes?: PrivateServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiToolAllOfDef
     */
    relationships?: PrivateServiceSpecificationApiToolRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolAttributesDef
 */
export interface PrivateServiceSpecificationApiToolAttributesDef {
    /**
     * Service Specification API Tool URL. 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    url: string;
    /**
     * Service Specification API Tool title; to provide a detailed information about the API Tool.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    title?: string | null;
    /**
     * Service Specification API Tool description; to provide a detailed information about the API Tool.
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiToolCategoryEnum}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    category: ServiceSpecificationApiToolCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolDef
 */
export interface PrivateServiceSpecificationApiToolDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolDef
     */
    type?: PrivateServiceSpecificationApiToolDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolAttributesDef}
     * @memberof PrivateServiceSpecificationApiToolDef
     */
    attributes?: PrivateServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiToolDef
     */
    relationships?: PrivateServiceSpecificationApiToolRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiToolDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationApiToolIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationApiToolIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiToolIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolIdentifierObjectDef
 */
export interface PrivateServiceSpecificationApiToolIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationApiToolIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiToolIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationApiToolListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiToolDef>}
     * @memberof PrivateServiceSpecificationApiToolListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationApiToolDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolListResponseBodyDef
 */
export interface PrivateServiceSpecificationApiToolListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationApiToolDef>}
     * @memberof PrivateServiceSpecificationApiToolListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationApiToolDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolRelationshipsDef
 */
export interface PrivateServiceSpecificationApiToolRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationApiToolRelationshipsDef
     */
    serviceSpecification: PrivateServiceSpecificationApiOperationRelationshipsServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolRequestBodyDef
 */
export interface PrivateServiceSpecificationApiToolRequestBodyDef {
    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationApiToolRequestBodyDef
     */
    data: object;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationApiToolResponseBodyDef
 */
export interface PrivateServiceSpecificationApiToolResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolResponseBodyDef
     */
    type?: PrivateServiceSpecificationApiToolResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationApiToolResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolAttributesDef}
     * @memberof PrivateServiceSpecificationApiToolResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationApiToolRelationshipsDef}
     * @memberof PrivateServiceSpecificationApiToolResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationApiToolRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationApiToolResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationAttributesDef
 */
export interface PrivateServiceSpecificationAttributesDef {
    /**
     * Service name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    name: string;
    /**
     * Service title, or customer-facing name; to provide the full name of the Service.
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    title?: string | null;
    /**
     * Service description; to provide a detailed information about the Service.
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    description?: string | null;
    /**
     * API Specification of the provided Service.
     * @type {object}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    apiSpecification?: object;
    /**
     * 
     * @type {ServiceSpecificationSecurityLevelEnum}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    securityLevel: ServiceSpecificationSecurityLevelEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationBodyDef
 */
export interface PrivateServiceSpecificationBodyDef {
    /**
     * 
     * @type {PrivateServiceSpecificationDef}
     * @memberof PrivateServiceSpecificationBodyDef
     */
    data: PrivateServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationDef
 */
export interface PrivateServiceSpecificationDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationDef
     */
    type?: PrivateServiceSpecificationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationIdentifierObjectAllOfDef
 */
export interface PrivateServiceSpecificationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectAllOfDef
     */
    type?: PrivateServiceSpecificationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationIdentifierObjectDef
 */
export interface PrivateServiceSpecificationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectDef
     */
    type?: PrivateServiceSpecificationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PrivateServiceSpecificationListResponseBodyAllOfDef
 */
export interface PrivateServiceSpecificationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationDef>}
     * @memberof PrivateServiceSpecificationListResponseBodyAllOfDef
     */
    data: Array<PrivateServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationListResponseBodyDef
 */
export interface PrivateServiceSpecificationListResponseBodyDef {
    /**
     * 
     * @type {Array<PrivateServiceSpecificationDef>}
     * @memberof PrivateServiceSpecificationListResponseBodyDef
     */
    data: Array<PrivateServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRelationshipsDef
 */
export interface PrivateServiceSpecificationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PrivateServiceSpecificationRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRelationshipsOwnerAccountDef
 */
export interface PrivateServiceSpecificationRelationshipsOwnerAccountDef {
    /**
     * 
     * @type {AccountIdentifierObjectDef}
     * @memberof PrivateServiceSpecificationRelationshipsOwnerAccountDef
     */
    data?: AccountIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationRequestBodyDef
 */
export interface PrivateServiceSpecificationRequestBodyDef {
    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationRequestBodyDef
     */
    data: object;
}
/**
 * 
 * @export
 * @interface PrivateServiceSpecificationResponseBodyDef
 */
export interface PrivateServiceSpecificationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    type?: PrivateServiceSpecificationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PrivateServiceSpecificationAttributesDef}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    attributes?: PrivateServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsDef}
     * @memberof PrivateServiceSpecificationResponseBodyDef
     */
    relationships?: PrivateServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PrivateServiceSpecificationResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATIONS = 'service-specifications'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationAllOfDef
 */
export interface PublicServiceSpecificationAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationAllOfDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationAllOfDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationAllOfDef
 */
export interface PublicServiceSpecificationApiOperationAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationAllOfDef
     */
    attributes?: PublicServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationAllOfDef
     */
    relationships?: PublicServiceSpecificationApiOperationRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationAttributesDef
 */
export interface PublicServiceSpecificationApiOperationAttributesDef {
    /**
     * API Operation name. Can be used instread of `id` to uniquely identify the Service within the damain (`account`/service-specification). </br>Format requirements: </br>- contains at most 255 characters </br>- contains only lowercase alphanumeric characters or \'-\' </br>- starts with an alphabetic character </br>- ends with an alphanumeric character 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    name?: string;
    /**
     * API Operation description; to provide a detailed information about the API Endpoint.
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiOperationHttpMethodEnum}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    httpMethod: ServiceSpecificationApiOperationHttpMethodEnum;
    /**
     * Path of the API Operation.
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    path: string;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    xCoordinate?: number | null;
    /**
     * API Operation X/Y diagram coordinate.
     * @type {number}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    yCoordinate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationBodyDef
 */
export interface PublicServiceSpecificationApiOperationBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationBodyDef
     */
    data: PublicServiceSpecificationApiOperationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationDef
 */
export interface PublicServiceSpecificationApiOperationDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    type?: PublicServiceSpecificationApiOperationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    attributes?: PublicServiceSpecificationApiOperationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationDef
     */
    relationships?: PublicServiceSpecificationApiOperationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationIdentifierObjectAllOfDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationIdentifierObjectDef
 */
export interface PublicServiceSpecificationApiOperationIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectDef
     */
    type?: PublicServiceSpecificationApiOperationIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationIdentifierObjectDefTypeEnum {
    SERVICE_API_OPERATIONS = 'service-api-operations'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkAllOfDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkAllOfDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkAttributesDef
 */
export interface PublicServiceSpecificationApiOperationLinkAttributesDef {
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PublicServiceSpecificationApiOperationLinkAttributesDef
     */
    outwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
    /**
     * 
     * @type {ServiceSpecificationApiOperationLinkPositionEnum}
     * @memberof PublicServiceSpecificationApiOperationLinkAttributesDef
     */
    inwardLinkPosition: ServiceSpecificationApiOperationLinkPositionEnum;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkDef}
     * @memberof PublicServiceSpecificationApiOperationLinkBodyDef
     */
    data: PublicServiceSpecificationApiOperationLinkDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkDef
 */
export interface PublicServiceSpecificationApiOperationLinkDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    type?: PublicServiceSpecificationApiOperationLinkDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkIdentifierObjectAllOfDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
 */
export interface PublicServiceSpecificationApiOperationLinkIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
     */
    type?: PublicServiceSpecificationApiOperationLinkIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkIdentifierObjectDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationLinkDef>}
     * @memberof PublicServiceSpecificationApiOperationLinkListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkListResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationLinkDef>}
     * @memberof PublicServiceSpecificationApiOperationLinkListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationApiOperationLinkDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkRelationshipsDef
 */
export interface PublicServiceSpecificationApiOperationLinkRelationshipsDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsDef
     */
    outwardApiOperation: PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsDef
     */
    inwardApiOperation: PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
 */
export interface PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationIdentifierObjectDef}
     * @memberof PublicServiceSpecificationApiOperationLinkRelationshipsOutwardApiOperationDef
     */
    data?: PublicServiceSpecificationApiOperationIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationLinkResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationLinkResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    type?: PublicServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkAttributesDef}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    attributes?: PublicServiceSpecificationApiOperationLinkAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationLinkRelationshipsDef}
     * @memberof PublicServiceSpecificationApiOperationLinkResponseBodyDef
     */
    relationships?: PublicServiceSpecificationApiOperationLinkRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiOperationLinkResponseBodyDefTypeEnum {
    SERVICE_API_OPERATION_LINKS = 'service-api-operation-links'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationApiOperationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationDef>}
     * @memberof PublicServiceSpecificationApiOperationListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationListResponseBodyDef
 */
export interface PublicServiceSpecificationApiOperationListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiOperationDef>}
     * @memberof PublicServiceSpecificationApiOperationListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationApiOperationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationRelationshipsDef
 */
export interface PublicServiceSpecificationApiOperationRelationshipsDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsServiceDef}
     * @memberof PublicServiceSpecificationApiOperationRelationshipsDef
     */
    service: PublicServiceSpecificationApiOperationRelationshipsServiceDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiOperationRelationshipsServiceDef
 */
export interface PublicServiceSpecificationApiOperationRelationshipsServiceDef {
    /**
     * 
     * @type {ServiceIdentifierObjectDef}
     * @memberof PublicServiceSpecificationApiOperationRelationshipsServiceDef
     */
    data?: ServiceIdentifierObjectDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolAllOfDef
 */
export interface PublicServiceSpecificationApiToolAllOfDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiToolAttributesDef}
     * @memberof PublicServiceSpecificationApiToolAllOfDef
     */
    attributes?: PublicServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiToolRelationshipsDef}
     * @memberof PublicServiceSpecificationApiToolAllOfDef
     */
    relationships?: PublicServiceSpecificationApiToolRelationshipsDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolAttributesDef
 */
export interface PublicServiceSpecificationApiToolAttributesDef {
    /**
     * Service Specification API Tool URL. 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    url: string;
    /**
     * Service Specification API Tool title; to provide a detailed information about the API Tool.
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    title?: string | null;
    /**
     * Service Specification API Tool description; to provide a detailed information about the API Tool.
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    description?: string | null;
    /**
     * 
     * @type {ServiceSpecificationApiToolCategoryEnum}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    category: ServiceSpecificationApiToolCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolAttributesDef
     */
    modifiedAt: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolBodyDef
 */
export interface PublicServiceSpecificationApiToolBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiToolDef}
     * @memberof PublicServiceSpecificationApiToolBodyDef
     */
    data: PublicServiceSpecificationApiToolDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolDef
 */
export interface PublicServiceSpecificationApiToolDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolDef
     */
    type?: PublicServiceSpecificationApiToolDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiToolAttributesDef}
     * @memberof PublicServiceSpecificationApiToolDef
     */
    attributes?: PublicServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiToolRelationshipsDef}
     * @memberof PublicServiceSpecificationApiToolDef
     */
    relationships?: PublicServiceSpecificationApiToolRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiToolDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolIdentifierObjectAllOfDef
 */
export interface PublicServiceSpecificationApiToolIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolIdentifierObjectAllOfDef
     */
    type?: PublicServiceSpecificationApiToolIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiToolIdentifierObjectAllOfDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolIdentifierObjectDef
 */
export interface PublicServiceSpecificationApiToolIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolIdentifierObjectDef
     */
    type?: PublicServiceSpecificationApiToolIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiToolIdentifierObjectDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationApiToolListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiToolDef>}
     * @memberof PublicServiceSpecificationApiToolListResponseBodyAllOfDef
     */
    data: Array<PublicServiceSpecificationApiToolDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolListResponseBodyDef
 */
export interface PublicServiceSpecificationApiToolListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationApiToolDef>}
     * @memberof PublicServiceSpecificationApiToolListResponseBodyDef
     */
    data: Array<PublicServiceSpecificationApiToolDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolRelationshipsDef
 */
export interface PublicServiceSpecificationApiToolRelationshipsDef {
    /**
     * 
     * @type {PublicServiceSpecificationApiOperationRelationshipsServiceDef}
     * @memberof PublicServiceSpecificationApiToolRelationshipsDef
     */
    service: PublicServiceSpecificationApiOperationRelationshipsServiceDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationApiToolResponseBodyDef
 */
export interface PublicServiceSpecificationApiToolResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolResponseBodyDef
     */
    type?: PublicServiceSpecificationApiToolResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationApiToolResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationApiToolAttributesDef}
     * @memberof PublicServiceSpecificationApiToolResponseBodyDef
     */
    attributes?: PublicServiceSpecificationApiToolAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationApiToolRelationshipsDef}
     * @memberof PublicServiceSpecificationApiToolResponseBodyDef
     */
    relationships?: PublicServiceSpecificationApiToolRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationApiToolResponseBodyDefTypeEnum {
    SERVICE_SPECIFICATION_API_TOOLS = 'service-specification-api-tools'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationAttributesDef
 */
export interface PublicServiceSpecificationAttributesDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    description?: string;
    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    apiSpecification?: object;
    /**
     * Shows if current User is subscribed to this Specification.
     * @type {boolean}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    isSubscribedTo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationAttributesDef
     */
    modifiedAt?: string;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationBodyDef
 */
export interface PublicServiceSpecificationBodyDef {
    /**
     * 
     * @type {PublicServiceSpecificationDef}
     * @memberof PublicServiceSpecificationBodyDef
     */
    data?: PublicServiceSpecificationDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationDef
 */
export interface PublicServiceSpecificationDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationDef
     */
    type?: PublicServiceSpecificationDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface PublicServiceSpecificationListResponseBodyAllOfDef
 */
export interface PublicServiceSpecificationListResponseBodyAllOfDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationDef>}
     * @memberof PublicServiceSpecificationListResponseBodyAllOfDef
     */
    data?: Array<PublicServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationListResponseBodyDef
 */
export interface PublicServiceSpecificationListResponseBodyDef {
    /**
     * 
     * @type {Array<PublicServiceSpecificationDef>}
     * @memberof PublicServiceSpecificationListResponseBodyDef
     */
    data?: Array<PublicServiceSpecificationDef>;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationRelationshipsDef
 */
export interface PublicServiceSpecificationRelationshipsDef {
    /**
     * 
     * @type {PrivateServiceSpecificationRelationshipsOwnerAccountDef}
     * @memberof PublicServiceSpecificationRelationshipsDef
     */
    ownerAccount: PrivateServiceSpecificationRelationshipsOwnerAccountDef;
}
/**
 * 
 * @export
 * @interface PublicServiceSpecificationResponseBodyDef
 */
export interface PublicServiceSpecificationResponseBodyDef {
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    type?: PublicServiceSpecificationResponseBodyDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    id?: string;
    /**
     * 
     * @type {PublicServiceSpecificationAttributesDef}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    attributes?: PublicServiceSpecificationAttributesDef;
    /**
     * 
     * @type {PublicServiceSpecificationRelationshipsDef}
     * @memberof PublicServiceSpecificationResponseBodyDef
     */
    relationships?: PublicServiceSpecificationRelationshipsDef;
}

/**
    * @export
    * @enum {string}
    */
export enum PublicServiceSpecificationResponseBodyDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface ServiceIdentifierObjectAllOfDef
 */
export interface ServiceIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectAllOfDef
     */
    type?: ServiceIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceIdentifierObjectAllOfDefTypeEnum {
    SERVICES = 'services'
}

/**
 * 
 * @export
 * @interface ServiceIdentifierObjectDef
 */
export interface ServiceIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectDef
     */
    type?: ServiceIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ServiceIdentifierObjectDefTypeEnum {
    SERVICES = 'services'
}

/**
 * HTTP Method of the API Operation.
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationApiOperationHttpMethodEnum {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    CONNECT = 'CONNECT',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE',
    PATCH = 'PATCH'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationApiOperationLinkPositionEnum {
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT'
}

/**
 * API Operation Tags.
 * @export
 * @interface ServiceSpecificationApiOperationTagsDef
 */
export interface ServiceSpecificationApiOperationTagsDef extends Array<string> {
}
/**
 * Defines the type of this API Tool: </br>- `UNDEFINED` [default] - \'Undefined\' </br>- `API_DOCUMENTATION` - \'API Documentation\' </br>- `API_SOURCE_CODE` - \'API Source Code\' </br>- `API_DEPLOYMENTS` - \'API Deployments\' </br>- `API_HEALTH` - \'API Health\' </br>- `API_SUPPORT` - \'API Support\' 
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationApiToolCategoryEnum {
    UNDEFINED = 'UNDEFINED',
    API_DOCUMENTATION = 'API_DOCUMENTATION',
    API_SOURCE_CODE = 'API_SOURCE_CODE',
    API_DEPLOYMENTS = 'API_DEPLOYMENTS',
    API_HEALTH = 'API_HEALTH',
    API_SUPPORT = 'API_SUPPORT'
}

/**
 * Defines if this Specification is publicly available for use: </br>- `PRIVATE` [default] - Service Specification is not visible to any other Account </br>- `PROTECTED` - Service Specification is only visible to the selected Accounts; see `relationships.sharedWithAccounts` </br>- `PUBLIC` - Service Specification is visible to the entire Internet 
 * @export
 * @enum {string}
 */

export enum ServiceSpecificationSecurityLevelEnum {
    PRIVATE = 'PRIVATE',
    PROTECTED = 'PROTECTED',
    PUBLIC = 'PUBLIC'
}

/**
 * **Note:** `refreshToken` will only expire once you used them to get new tokens.
 * @export
 * @interface TokensDef
 */
export interface TokensDef {
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    refreshToken?: string;
    /**
     * 
     * @type {string}
     * @memberof TokensDef
     */
    tokenType?: string;
    /**
     * Defines when the `accessToken` will expire. In seconds.
     * @type {number}
     * @memberof TokensDef
     */
    expiresIn?: number;
}
/**
 * 
 * @export
 * @interface UserIdentifierObjectAllOfDef
 */
export interface UserIdentifierObjectAllOfDef {
    /**
     * 
     * @type {string}
     * @memberof UserIdentifierObjectAllOfDef
     */
    type?: UserIdentifierObjectAllOfDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserIdentifierObjectAllOfDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserIdentifierObjectAllOfDefTypeEnum {
    USERS = 'users'
}

/**
 * 
 * @export
 * @interface UserIdentifierObjectDef
 */
export interface UserIdentifierObjectDef {
    /**
     * 
     * @type {string}
     * @memberof UserIdentifierObjectDef
     */
    type?: UserIdentifierObjectDefTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UserIdentifierObjectDef
     */
    id?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum UserIdentifierObjectDefTypeEnum {
    USERS = 'users'
}


/**
 * AuthorisationApi - axios parameter creator
 * @export
 */
export const AuthorisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyAuthenticatePost: async (aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-key/authenticate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication apiKeyAuth required
            await setApiKeyToObject(localVarHeaderParameter, "API-KEY", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorisationApi - functional programming interface
 * @export
 */
export const AuthorisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorisationApiAxiosParamCreator(configuration)
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKeyAuthenticatePost(aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKeyAuthenticatePost(aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorisationApi - factory interface
 * @export
 */
export const AuthorisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorisationApiFp(configuration)
    return {
        /**
         * API Key is provided on demand
         * @summary Authenticate via API Key.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKeyAuthenticatePost(aMeta?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKeyAuthenticatePost(aMeta, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiKeyAuthenticatePost operation in AuthorisationApi.
 * @export
 * @interface AuthorisationApiApiKeyAuthenticatePostRequest
 */
export interface AuthorisationApiApiKeyAuthenticatePostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof AuthorisationApiApiKeyAuthenticatePost
     */
    readonly aMeta?: string
}

/**
 * AuthorisationApi - object-oriented interface
 * @export
 * @class AuthorisationApi
 * @extends {BaseAPI}
 */
export class AuthorisationApi extends BaseAPI {
    /**
     * API Key is provided on demand
     * @summary Authenticate via API Key.
     * @param {AuthorisationApiApiKeyAuthenticatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiKeyAuthenticatePost(requestParameters: AuthorisationApiApiKeyAuthenticatePostRequest = {}, options?: any) {
        return AuthorisationApiFp(this.configuration).apiKeyAuthenticatePost(requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MyAccountsApi - axios parameter creator
 * @export
 */
export const MyAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get User\'s Account information.
         * @summary Get User\'s Accounts.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAccountsGet: async (aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/my-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MyAccountsApi - functional programming interface
 * @export
 */
export const MyAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MyAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get User\'s Account information.
         * @summary Get User\'s Accounts.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async myAccountsGet(aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.myAccountsGet(aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MyAccountsApi - factory interface
 * @export
 */
export const MyAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MyAccountsApiFp(configuration)
    return {
        /**
         * Get User\'s Account information.
         * @summary Get User\'s Accounts.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        myAccountsGet(aMeta?: string, options?: any): AxiosPromise<object> {
            return localVarFp.myAccountsGet(aMeta, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for myAccountsGet operation in MyAccountsApi.
 * @export
 * @interface MyAccountsApiMyAccountsGetRequest
 */
export interface MyAccountsApiMyAccountsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof MyAccountsApiMyAccountsGet
     */
    readonly aMeta?: string
}

/**
 * MyAccountsApi - object-oriented interface
 * @export
 * @class MyAccountsApi
 * @extends {BaseAPI}
 */
export class MyAccountsApi extends BaseAPI {
    /**
     * Get User\'s Account information.
     * @summary Get User\'s Accounts.
     * @param {MyAccountsApiMyAccountsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MyAccountsApi
     */
    public myAccountsGet(requestParameters: MyAccountsApiMyAccountsGetRequest = {}, options?: any) {
        return MyAccountsApiFp(this.configuration).myAccountsGet(requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateAccountUsersApi - axios parameter creator
 * @export
 */
export const PrivateAccountUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Queries Private Account Users. The operation is accessible to any user of a Private Account being queried.
         * @summary Query Private Account Users
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersGet: async (accountId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdUsersGet', 'accountId', accountId)
            const localVarPath = `/private-accounts/{account-id}/users`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user", "administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * A User is added to a Private Account by an email with the specified role of an Owner or of a Maintainer. Owner users have access to the Private Account management APIs: they can query, update or delete Private Accounts  and add or remove another users. Maintainers have an access to the account resources, but are not able to manage  an account e.g. add or remove another users.
         * @summary Add a User to a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersPost: async (accountId: string, inlineObjectDef?: InlineObjectDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdUsersPost', 'accountId', accountId)
            const localVarPath = `/private-accounts/{account-id}/users`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObjectDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes a Private Account User from a Private Account. A Private Account User is not able to remove himself from a Private Account.
         * @summary Remove a Private Account User from a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} userId User identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersUserIdDelete: async (accountId: string, userId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdUsersUserIdDelete', 'accountId', accountId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('privateAccountsAccountIdUsersUserIdDelete', 'userId', userId)
            const localVarPath = `/private-accounts/{account-id}/users/{user-id}`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"user-id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateAccountUsersApi - functional programming interface
 * @export
 */
export const PrivateAccountUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateAccountUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Queries Private Account Users. The operation is accessible to any user of a Private Account being queried.
         * @summary Query Private Account Users
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdUsersGet(accountId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdUsersGet(accountId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * A User is added to a Private Account by an email with the specified role of an Owner or of a Maintainer. Owner users have access to the Private Account management APIs: they can query, update or delete Private Accounts  and add or remove another users. Maintainers have an access to the account resources, but are not able to manage  an account e.g. add or remove another users.
         * @summary Add a User to a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdUsersPost(accountId: string, inlineObjectDef?: InlineObjectDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdUsersPost(accountId, inlineObjectDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes a Private Account User from a Private Account. A Private Account User is not able to remove himself from a Private Account.
         * @summary Remove a Private Account User from a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} userId User identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdUsersUserIdDelete(accountId: string, userId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdUsersUserIdDelete(accountId, userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateAccountUsersApi - factory interface
 * @export
 */
export const PrivateAccountUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateAccountUsersApiFp(configuration)
    return {
        /**
         * Queries Private Account Users. The operation is accessible to any user of a Private Account being queried.
         * @summary Query Private Account Users
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersGet(accountId: string, aMeta?: string, options?: any): AxiosPromise<object> {
            return localVarFp.privateAccountsAccountIdUsersGet(accountId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * A User is added to a Private Account by an email with the specified role of an Owner or of a Maintainer. Owner users have access to the Private Account management APIs: they can query, update or delete Private Accounts  and add or remove another users. Maintainers have an access to the account resources, but are not able to manage  an account e.g. add or remove another users.
         * @summary Add a User to a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {InlineObjectDef} [inlineObjectDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersPost(accountId: string, inlineObjectDef?: InlineObjectDef, options?: any): AxiosPromise<object> {
            return localVarFp.privateAccountsAccountIdUsersPost(accountId, inlineObjectDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Removes a Private Account User from a Private Account. A Private Account User is not able to remove himself from a Private Account.
         * @summary Remove a Private Account User from a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} userId User identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdUsersUserIdDelete(accountId: string, userId: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.privateAccountsAccountIdUsersUserIdDelete(accountId, userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privateAccountsAccountIdUsersGet operation in PrivateAccountUsersApi.
 * @export
 * @interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersGetRequest
 */
export interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersGetRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersGet
     */
    readonly accountId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for privateAccountsAccountIdUsersPost operation in PrivateAccountUsersApi.
 * @export
 * @interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest
 */
export interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersPost
     */
    readonly accountId: string

    /**
     * 
     * @type {InlineObjectDef}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersPost
     */
    readonly inlineObjectDef?: InlineObjectDef
}

/**
 * Request parameters for privateAccountsAccountIdUsersUserIdDelete operation in PrivateAccountUsersApi.
 * @export
 * @interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDeleteRequest
 */
export interface PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDeleteRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDelete
     */
    readonly accountId: string

    /**
     * User identifier
     * @type {string}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDelete
     */
    readonly userId: string

    /**
     * 
     * @type {object}
     * @memberof PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDelete
     */
    readonly body?: object
}

/**
 * PrivateAccountUsersApi - object-oriented interface
 * @export
 * @class PrivateAccountUsersApi
 * @extends {BaseAPI}
 */
export class PrivateAccountUsersApi extends BaseAPI {
    /**
     * Queries Private Account Users. The operation is accessible to any user of a Private Account being queried.
     * @summary Query Private Account Users
     * @param {PrivateAccountUsersApiPrivateAccountsAccountIdUsersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountUsersApi
     */
    public privateAccountsAccountIdUsersGet(requestParameters: PrivateAccountUsersApiPrivateAccountsAccountIdUsersGetRequest, options?: any) {
        return PrivateAccountUsersApiFp(this.configuration).privateAccountsAccountIdUsersGet(requestParameters.accountId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * A User is added to a Private Account by an email with the specified role of an Owner or of a Maintainer. Owner users have access to the Private Account management APIs: they can query, update or delete Private Accounts  and add or remove another users. Maintainers have an access to the account resources, but are not able to manage  an account e.g. add or remove another users.
     * @summary Add a User to a Private Account.
     * @param {PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountUsersApi
     */
    public privateAccountsAccountIdUsersPost(requestParameters: PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest, options?: any) {
        return PrivateAccountUsersApiFp(this.configuration).privateAccountsAccountIdUsersPost(requestParameters.accountId, requestParameters.inlineObjectDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Removes a Private Account User from a Private Account. A Private Account User is not able to remove himself from a Private Account.
     * @summary Remove a Private Account User from a Private Account.
     * @param {PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountUsersApi
     */
    public privateAccountsAccountIdUsersUserIdDelete(requestParameters: PrivateAccountUsersApiPrivateAccountsAccountIdUsersUserIdDeleteRequest, options?: any) {
        return PrivateAccountUsersApiFp(this.configuration).privateAccountsAccountIdUsersUserIdDelete(requestParameters.accountId, requestParameters.userId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateAccountsApi - axios parameter creator
 * @export
 */
export const PrivateAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes a Private Account. If a Private Account being deleted has any service specifications associated with it, the operation will fail
         * @summary Delete a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdDelete: async (accountId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdDelete', 'accountId', accountId)
            const localVarPath = `/private-accounts/{account-id}`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a single Private Account. The operation is accessible to any user of a Private Account being queried.
         * @summary Get a single Private Account
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdGet: async (accountId: string, aMeta?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdGet', 'accountId', accountId)
            const localVarPath = `/private-accounts/{account-id}`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user", "administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a Private Account.
         * @summary Update a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdPut: async (accountId: string, aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('privateAccountsAccountIdPut', 'accountId', accountId)
            const localVarPath = `/private-accounts/{account-id}`
                .replace(`{${"account-id"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateAccountBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Private Account. The creator user becomes the owner of this Private Account, with the ability to update, delete it or add other users to it.
         * @summary Create a Private Account.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsPost: async (aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/private-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["administrator"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateAccountBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateAccountsApi - functional programming interface
 * @export
 */
export const PrivateAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes a Private Account. If a Private Account being deleted has any service specifications associated with it, the operation will fail
         * @summary Delete a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdDelete(accountId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdDelete(accountId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a single Private Account. The operation is accessible to any user of a Private Account being queried.
         * @summary Get a single Private Account
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdGet(accountId: string, aMeta?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAccountResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdGet(accountId, aMeta, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a Private Account.
         * @summary Update a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsAccountIdPut(accountId: string, aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAccountResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsAccountIdPut(accountId, aMeta, privateAccountBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Private Account. The creator user becomes the owner of this Private Account, with the ability to update, delete it or add other users to it.
         * @summary Create a Private Account.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async privateAccountsPost(aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateAccountResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.privateAccountsPost(aMeta, privateAccountBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateAccountsApi - factory interface
 * @export
 */
export const PrivateAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateAccountsApiFp(configuration)
    return {
        /**
         * Deletes a Private Account. If a Private Account being deleted has any service specifications associated with it, the operation will fail
         * @summary Delete a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdDelete(accountId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.privateAccountsAccountIdDelete(accountId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a single Private Account. The operation is accessible to any user of a Private Account being queried.
         * @summary Get a single Private Account
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdGet(accountId: string, aMeta?: string, options?: any): AxiosPromise<PrivateAccountResponseBodyDef> {
            return localVarFp.privateAccountsAccountIdGet(accountId, aMeta, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a Private Account.
         * @summary Update a Private Account.
         * @param {string} accountId Private Account identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsAccountIdPut(accountId: string, aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options?: any): AxiosPromise<PrivateAccountResponseBodyDef> {
            return localVarFp.privateAccountsAccountIdPut(accountId, aMeta, privateAccountBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Private Account. The creator user becomes the owner of this Private Account, with the ability to update, delete it or add other users to it.
         * @summary Create a Private Account.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateAccountBodyDef} [privateAccountBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        privateAccountsPost(aMeta?: string, privateAccountBodyDef?: PrivateAccountBodyDef, options?: any): AxiosPromise<PrivateAccountResponseBodyDef> {
            return localVarFp.privateAccountsPost(aMeta, privateAccountBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for privateAccountsAccountIdDelete operation in PrivateAccountsApi.
 * @export
 * @interface PrivateAccountsApiPrivateAccountsAccountIdDeleteRequest
 */
export interface PrivateAccountsApiPrivateAccountsAccountIdDeleteRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdDelete
     */
    readonly accountId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdDelete
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdDelete
     */
    readonly body?: object
}

/**
 * Request parameters for privateAccountsAccountIdGet operation in PrivateAccountsApi.
 * @export
 * @interface PrivateAccountsApiPrivateAccountsAccountIdGetRequest
 */
export interface PrivateAccountsApiPrivateAccountsAccountIdGetRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdGet
     */
    readonly accountId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdGet
     */
    readonly aMeta?: string
}

/**
 * Request parameters for privateAccountsAccountIdPut operation in PrivateAccountsApi.
 * @export
 * @interface PrivateAccountsApiPrivateAccountsAccountIdPutRequest
 */
export interface PrivateAccountsApiPrivateAccountsAccountIdPutRequest {
    /**
     * Private Account identifier
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdPut
     */
    readonly accountId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateAccountBodyDef}
     * @memberof PrivateAccountsApiPrivateAccountsAccountIdPut
     */
    readonly privateAccountBodyDef?: PrivateAccountBodyDef
}

/**
 * Request parameters for privateAccountsPost operation in PrivateAccountsApi.
 * @export
 * @interface PrivateAccountsApiPrivateAccountsPostRequest
 */
export interface PrivateAccountsApiPrivateAccountsPostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateAccountsApiPrivateAccountsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateAccountBodyDef}
     * @memberof PrivateAccountsApiPrivateAccountsPost
     */
    readonly privateAccountBodyDef?: PrivateAccountBodyDef
}

/**
 * PrivateAccountsApi - object-oriented interface
 * @export
 * @class PrivateAccountsApi
 * @extends {BaseAPI}
 */
export class PrivateAccountsApi extends BaseAPI {
    /**
     * Deletes a Private Account. If a Private Account being deleted has any service specifications associated with it, the operation will fail
     * @summary Delete a Private Account.
     * @param {PrivateAccountsApiPrivateAccountsAccountIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountsApi
     */
    public privateAccountsAccountIdDelete(requestParameters: PrivateAccountsApiPrivateAccountsAccountIdDeleteRequest, options?: any) {
        return PrivateAccountsApiFp(this.configuration).privateAccountsAccountIdDelete(requestParameters.accountId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a single Private Account. The operation is accessible to any user of a Private Account being queried.
     * @summary Get a single Private Account
     * @param {PrivateAccountsApiPrivateAccountsAccountIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountsApi
     */
    public privateAccountsAccountIdGet(requestParameters: PrivateAccountsApiPrivateAccountsAccountIdGetRequest, options?: any) {
        return PrivateAccountsApiFp(this.configuration).privateAccountsAccountIdGet(requestParameters.accountId, requestParameters.aMeta, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a Private Account.
     * @summary Update a Private Account.
     * @param {PrivateAccountsApiPrivateAccountsAccountIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountsApi
     */
    public privateAccountsAccountIdPut(requestParameters: PrivateAccountsApiPrivateAccountsAccountIdPutRequest, options?: any) {
        return PrivateAccountsApiFp(this.configuration).privateAccountsAccountIdPut(requestParameters.accountId, requestParameters.aMeta, requestParameters.privateAccountBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Private Account. The creator user becomes the owner of this Private Account, with the ability to update, delete it or add other users to it.
     * @summary Create a Private Account.
     * @param {PrivateAccountsApiPrivateAccountsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountsApi
     */
    public privateAccountsPost(requestParameters: PrivateAccountsApiPrivateAccountsPostRequest = {}, options?: any) {
        return PrivateAccountsApiFp(this.configuration).privateAccountsPost(requestParameters.aMeta, requestParameters.privateAccountBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateServiceSpecificationsApi - axios parameter creator
 * @export
 */
export const PrivateServiceSpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsGet: async (aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsPost: async (aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/service-specifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["basic_user"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdDelete: async (serviceSpecificationId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdDelete', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdGet: async (serviceSpecificationId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdPut: async (serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdPut', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateServiceSpecificationsApi - functional programming interface
 * @export
 */
export const PrivateServiceSpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateServiceSpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsGet(aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsGet(aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsPost(aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsPost(aMeta, privateServiceSpecificationRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId, aMeta, privateServiceSpecificationBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateServiceSpecificationsApi - factory interface
 * @export
 */
export const PrivateServiceSpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateServiceSpecificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Service Specifications.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsGet(aMeta?: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationListResponseBodyDef> {
            return localVarFp.serviceSpecificationsGet(aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
         * @summary Create a Service Specification
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationRequestBodyDef} [privateServiceSpecificationRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsPost(aMeta?: string, privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsPost(aMeta, privateServiceSpecificationRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing Service Specification. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdDelete(serviceSpecificationId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdGet(serviceSpecificationId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification. Can be used by Service Specification owner.
         * @summary Update a single Service Specification.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationBodyDef} [privateServiceSpecificationBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdPut(serviceSpecificationId, aMeta, privateServiceSpecificationBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceSpecificationsGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsGet
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsGet
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsPost operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsPostRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsPostRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationRequestBodyDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsPost
     */
    readonly privateServiceSpecificationRequestBodyDef?: PrivateServiceSpecificationRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdDelete operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDelete
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDelete
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDelete
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdGet operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGet
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGet
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGet
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdPut operation in PrivateServiceSpecificationsApi.
 * @export
 * @interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest
 */
export interface PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationBodyDef}
     * @memberof PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPut
     */
    readonly privateServiceSpecificationBodyDef?: PrivateServiceSpecificationBodyDef
}

/**
 * PrivateServiceSpecificationsApi - object-oriented interface
 * @export
 * @class PrivateServiceSpecificationsApi
 * @extends {BaseAPI}
 */
export class PrivateServiceSpecificationsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Service Specifications.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsGetRequest = {}, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsGet(requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Service Specification. The creator becomes the owner of this Specification, so only they can update/ publish/ suppress/ delete it. <br> By default the Specification is unpublished, see the parameter <code>\"securityLevel\"</code>. <br> Once the Specification is published, it becomes visible in the Services Catalog, so that potential API Consumers can see it, subscribe to it and start using. 
     * @summary Create a Service Specification
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsPost(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsPostRequest = {}, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsPost(requestParameters.aMeta, requestParameters.privateServiceSpecificationRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing Service Specification. Can be used by Service Specification owner.
     * @summary Delete a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdDelete(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdDeleteRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdDelete(requestParameters.serviceSpecificationId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdGet(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdGetRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdGet(requestParameters.serviceSpecificationId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification. Can be used by Service Specification owner.
     * @summary Update a single Service Specification.
     * @param {PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsApi
     */
    public serviceSpecificationsServiceSpecificationIdPut(requestParameters: PrivateServiceSpecificationsApiServiceSpecificationsServiceSpecificationIdPutRequest, options?: any) {
        return PrivateServiceSpecificationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdPut(requestParameters.serviceSpecificationId, requestParameters.aMeta, requestParameters.privateServiceSpecificationBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateServiceSpecificationsAPIOperationsApi - axios parameter creator
 * @export
 */
export const PrivateServiceSpecificationsAPIOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksGet: async (serviceSpecificationId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationLinksGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operation-links`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksPut: async (serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationLinksPut', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operation-links`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiOperationPatchRequestBodyDef} [privateServiceSpecificationApiOperationPatchRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch: async (serviceSpecificationId: string, apiOperationId: string, aMeta?: string, privateServiceSpecificationApiOperationPatchRequestBodyDef?: PrivateServiceSpecificationApiOperationPatchRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch', 'serviceSpecificationId', serviceSpecificationId)
            // verify required parameter 'apiOperationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch', 'apiOperationId', apiOperationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operations/{api-operation-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)))
                .replace(`{${"api-operation-id"}}`, encodeURIComponent(String(apiOperationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationApiOperationPatchRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsGet: async (serviceSpecificationId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiOperationsGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-operations`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateServiceSpecificationsAPIOperationsApi - functional programming interface
 * @export
 */
export const PrivateServiceSpecificationsAPIOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateServiceSpecificationsAPIOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiOperationPatchRequestBodyDef} [privateServiceSpecificationApiOperationPatchRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId: string, apiOperationId: string, aMeta?: string, privateServiceSpecificationApiOperationPatchRequestBodyDef?: PrivateServiceSpecificationApiOperationPatchRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId, apiOperationId, aMeta, privateServiceSpecificationApiOperationPatchRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiOperationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateServiceSpecificationsAPIOperationsApi - factory interface
 * @export
 */
export const PrivateServiceSpecificationsAPIOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateServiceSpecificationsAPIOperationsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(serviceSpecificationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Service Specification API Operation Links.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef} [privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId: string, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(serviceSpecificationId, privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Operation.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiOperationId Service Specification API Operation identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiOperationPatchRequestBodyDef} [privateServiceSpecificationApiOperationPatchRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId: string, apiOperationId: string, aMeta?: string, privateServiceSpecificationApiOperationPatchRequestBodyDef?: PrivateServiceSpecificationApiOperationPatchRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(serviceSpecificationId, apiOperationId, aMeta, privateServiceSpecificationApiOperationPatchRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service Specification API Operations.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationApiOperationListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiOperationsGet(serviceSpecificationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationLinksGet operation in PrivateServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest
 */
export interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGet
     */
    readonly serviceSpecificationId: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGet
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationLinksPut operation in PrivateServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest
 */
export interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPut
     */
    readonly serviceSpecificationId: string

    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPut
     */
    readonly privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef?: PrivateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch operation in PrivateServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest
 */
export interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly serviceSpecificationId: string

    /**
     * Service Specification API Operation identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly apiOperationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationApiOperationPatchRequestBodyDef}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch
     */
    readonly privateServiceSpecificationApiOperationPatchRequestBodyDef?: PrivateServiceSpecificationApiOperationPatchRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiOperationsGet operation in PrivateServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest
 */
export interface PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGet
     */
    readonly serviceSpecificationId: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGet
     */
    readonly body?: object
}

/**
 * PrivateServiceSpecificationsAPIOperationsApi - object-oriented interface
 * @export
 * @class PrivateServiceSpecificationsAPIOperationsApi
 * @extends {BaseAPI}
 */
export class PrivateServiceSpecificationsAPIOperationsApi extends BaseAPI {
    /**
     * 
     * @summary List of Service Specification API Operation Links.
     * @param {PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIOperationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(requestParameters: PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksGetRequest, options?: any) {
        return PrivateServiceSpecificationsAPIOperationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationLinksGet(requestParameters.serviceSpecificationId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Service Specification API Operation Links.
     * @param {PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIOperationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(requestParameters: PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationLinksPutRequest, options?: any) {
        return PrivateServiceSpecificationsAPIOperationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationLinksPut(requestParameters.serviceSpecificationId, requestParameters.privateServiceSpecificationApiOperationLinkListUnidentifiedRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification API Operation. Can be used by Service Specification owner.
     * @summary Update a single Service Specification API Operation.
     * @param {PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIOperationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(requestParameters: PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatchRequest, options?: any) {
        return PrivateServiceSpecificationsAPIOperationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationsApiOperationIdPatch(requestParameters.serviceSpecificationId, requestParameters.apiOperationId, requestParameters.aMeta, requestParameters.privateServiceSpecificationApiOperationPatchRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service Specification API Operations.
     * @param {PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIOperationsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiOperationsGet(requestParameters: PrivateServiceSpecificationsAPIOperationsApiServiceSpecificationsServiceSpecificationIdApiOperationsGetRequest, options?: any) {
        return PrivateServiceSpecificationsAPIOperationsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiOperationsGet(requestParameters.serviceSpecificationId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PrivateServiceSpecificationsAPIToolsApi - axios parameter creator
 * @export
 */
export const PrivateServiceSpecificationsAPIToolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete: async (serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete', 'serviceSpecificationId', serviceSpecificationId)
            // verify required parameter 'apiToolId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete', 'apiToolId', apiToolId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-tools/{api-tool-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)))
                .replace(`{${"api-tool-id"}}`, encodeURIComponent(String(apiToolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet: async (serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet', 'serviceSpecificationId', serviceSpecificationId)
            // verify required parameter 'apiToolId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet', 'apiToolId', apiToolId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-tools/{api-tool-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)))
                .replace(`{${"api-tool-id"}}`, encodeURIComponent(String(apiToolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut: async (serviceSpecificationId: string, apiToolId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut', 'serviceSpecificationId', serviceSpecificationId)
            // verify required parameter 'apiToolId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut', 'apiToolId', apiToolId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-tools/{api-tool-id}`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)))
                .replace(`{${"api-tool-id"}}`, encodeURIComponent(String(apiToolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationApiToolRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsGet: async (serviceSpecificationId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsGet', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-tools`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Service Specification API Tool.
         * @summary Create a Service Specification API Tool
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsPost: async (serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceSpecificationId' is not null or undefined
            assertParamExists('serviceSpecificationsServiceSpecificationIdApiToolsPost', 'serviceSpecificationId', serviceSpecificationId)
            const localVarPath = `/service-specifications/{service-specification-id}/api-tools`
                .replace(`{${"service-specification-id"}}`, encodeURIComponent(String(serviceSpecificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(privateServiceSpecificationApiToolRequestBodyDef, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateServiceSpecificationsAPIToolsApi - functional programming interface
 * @export
 */
export const PrivateServiceSpecificationsAPIToolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateServiceSpecificationsAPIToolsApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(serviceSpecificationId, apiToolId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(serviceSpecificationId, apiToolId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(serviceSpecificationId: string, apiToolId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(serviceSpecificationId, apiToolId, aMeta, privateServiceSpecificationApiToolRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiToolsGet(serviceSpecificationId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiToolListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiToolsGet(serviceSpecificationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Service Specification API Tool.
         * @summary Create a Service Specification API Tool
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async serviceSpecificationsServiceSpecificationIdApiToolsPost(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.serviceSpecificationsServiceSpecificationIdApiToolsPost(serviceSpecificationId, aMeta, privateServiceSpecificationApiToolRequestBodyDef, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateServiceSpecificationsAPIToolsApi - factory interface
 * @export
 */
export const PrivateServiceSpecificationsAPIToolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateServiceSpecificationsAPIToolsApiFp(configuration)
    return {
        /**
         * Deletes an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Delete a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<void> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(serviceSpecificationId, apiToolId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(serviceSpecificationId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(serviceSpecificationId, apiToolId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing Service Specification API Tool. Can be used by Service Specification owner.
         * @summary Update a single Service Specification API Tool.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(serviceSpecificationId: string, apiToolId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(serviceSpecificationId, apiToolId, aMeta, privateServiceSpecificationApiToolRequestBodyDef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsGet(serviceSpecificationId: string, body?: object, options?: any): AxiosPromise<PrivateServiceSpecificationApiToolListResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiToolsGet(serviceSpecificationId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Service Specification API Tool.
         * @summary Create a Service Specification API Tool
         * @param {string} serviceSpecificationId Service Specification identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {PrivateServiceSpecificationApiToolRequestBodyDef} [privateServiceSpecificationApiToolRequestBodyDef] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        serviceSpecificationsServiceSpecificationIdApiToolsPost(serviceSpecificationId: string, aMeta?: string, privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef, options?: any): AxiosPromise<PrivateServiceSpecificationApiToolResponseBodyDef> {
            return localVarFp.serviceSpecificationsServiceSpecificationIdApiToolsPost(serviceSpecificationId, aMeta, privateServiceSpecificationApiToolRequestBodyDef, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete operation in PrivateServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDeleteRequest
 */
export interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDeleteRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete
     */
    readonly serviceSpecificationId: string

    /**
     * Service Specification API Tool identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete
     */
    readonly apiToolId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet operation in PrivateServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGetRequest
 */
export interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet
     */
    readonly serviceSpecificationId: string

    /**
     * Service Specification API Tool identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet
     */
    readonly apiToolId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut operation in PrivateServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPutRequest
 */
export interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPutRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut
     */
    readonly serviceSpecificationId: string

    /**
     * Service Specification API Tool identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut
     */
    readonly apiToolId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationApiToolRequestBodyDef}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut
     */
    readonly privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiToolsGet operation in PrivateServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGetRequest
 */
export interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGetRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGet
     */
    readonly serviceSpecificationId: string

    /**
     * 
     * @type {object}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGet
     */
    readonly body?: object
}

/**
 * Request parameters for serviceSpecificationsServiceSpecificationIdApiToolsPost operation in PrivateServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest
 */
export interface PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest {
    /**
     * Service Specification identifier
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPost
     */
    readonly serviceSpecificationId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {PrivateServiceSpecificationApiToolRequestBodyDef}
     * @memberof PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPost
     */
    readonly privateServiceSpecificationApiToolRequestBodyDef?: PrivateServiceSpecificationApiToolRequestBodyDef
}

/**
 * PrivateServiceSpecificationsAPIToolsApi - object-oriented interface
 * @export
 * @class PrivateServiceSpecificationsAPIToolsApi
 * @extends {BaseAPI}
 */
export class PrivateServiceSpecificationsAPIToolsApi extends BaseAPI {
    /**
     * Deletes an existing Service Specification API Tool. Can be used by Service Specification owner.
     * @summary Delete a single Service Specification API Tool.
     * @param {PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIToolsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(requestParameters: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdDeleteRequest, options?: any) {
        return PrivateServiceSpecificationsAPIToolsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(requestParameters.serviceSpecificationId, requestParameters.apiToolId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service Specification API Tool.
     * @param {PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIToolsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(requestParameters: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdGetRequest, options?: any) {
        return PrivateServiceSpecificationsAPIToolsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdGet(requestParameters.serviceSpecificationId, requestParameters.apiToolId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing Service Specification API Tool. Can be used by Service Specification owner.
     * @summary Update a single Service Specification API Tool.
     * @param {PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIToolsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(requestParameters: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsApiToolIdPutRequest, options?: any) {
        return PrivateServiceSpecificationsAPIToolsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(requestParameters.serviceSpecificationId, requestParameters.apiToolId, requestParameters.aMeta, requestParameters.privateServiceSpecificationApiToolRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service Specification API Tools.
     * @param {PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIToolsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiToolsGet(requestParameters: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsGetRequest, options?: any) {
        return PrivateServiceSpecificationsAPIToolsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiToolsGet(requestParameters.serviceSpecificationId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Service Specification API Tool.
     * @summary Create a Service Specification API Tool
     * @param {PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateServiceSpecificationsAPIToolsApi
     */
    public serviceSpecificationsServiceSpecificationIdApiToolsPost(requestParameters: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest, options?: any) {
        return PrivateServiceSpecificationsAPIToolsApiFp(this.configuration).serviceSpecificationsServiceSpecificationIdApiToolsPost(requestParameters.serviceSpecificationId, requestParameters.aMeta, requestParameters.privateServiceSpecificationApiToolRequestBodyDef, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesGet: async (aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, body?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (filterIsSubscribedByCurrentAccount !== undefined) {
                localVarQueryParameter['filter[is_subscribed_by_current_account]'] = filterIsSubscribedByCurrentAccount;
            }

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdGet: async (serviceId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesGet(aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesGet(aMeta, filterIsSubscribedByCurrentAccount, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdGet(serviceId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdGet(serviceId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Paginated list of Services.
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {boolean} [filterIsSubscribedByCurrentAccount] Filter by Services subscribed by current Account.
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesGet(aMeta?: string, filterIsSubscribedByCurrentAccount?: boolean, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationListResponseBodyDef> {
            return localVarFp.servicesGet(aMeta, filterIsSubscribedByCurrentAccount, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a single Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdGet(serviceId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdGet(serviceId, aMeta, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesGetRequest
 */
export interface PublicServiceSpecificationsApiServicesGetRequest {
    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesGet
     */
    readonly aMeta?: string

    /**
     * Filter by Services subscribed by current Account.
     * @type {boolean}
     * @memberof PublicServiceSpecificationsApiServicesGet
     */
    readonly filterIsSubscribedByCurrentAccount?: boolean

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsApiServicesGet
     */
    readonly body?: object
}

/**
 * Request parameters for servicesServiceIdGet operation in PublicServiceSpecificationsApi.
 * @export
 * @interface PublicServiceSpecificationsApiServicesServiceIdGetRequest
 */
export interface PublicServiceSpecificationsApiServicesServiceIdGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdGet
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdGet
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsApiServicesServiceIdGet
     */
    readonly body?: object
}

/**
 * PublicServiceSpecificationsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationsApi extends BaseAPI {
    /**
     * 
     * @summary Paginated list of Services.
     * @param {PublicServiceSpecificationsApiServicesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesGet(requestParameters: PublicServiceSpecificationsApiServicesGetRequest = {}, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesGet(requestParameters.aMeta, requestParameters.filterIsSubscribedByCurrentAccount, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a single Service.
     * @param {PublicServiceSpecificationsApiServicesServiceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsApi
     */
    public servicesServiceIdGet(requestParameters: PublicServiceSpecificationsApiServicesServiceIdGetRequest, options?: any) {
        return PublicServiceSpecificationsApiFp(this.configuration).servicesServiceIdGet(requestParameters.serviceId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationsAPIOperationsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationsAPIOperationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationLinksGet: async (serviceId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiOperationLinksGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/api-operation-links`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationsGet: async (serviceId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiOperationsGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/api-operations`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationsAPIOperationsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationsAPIOperationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationsAPIOperationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiOperationLinksGet(serviceId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiOperationLinkListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiOperationLinksGet(serviceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiOperationsGet(serviceId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiOperationListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiOperationsGet(serviceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationsAPIOperationsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationsAPIOperationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationsAPIOperationsApiFp(configuration)
    return {
        /**
         * 
         * @summary List of Service API Operation Links.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationLinksGet(serviceId: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationApiOperationLinkListResponseBodyDef> {
            return localVarFp.servicesServiceIdApiOperationLinksGet(serviceId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service API Operations.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiOperationsGet(serviceId: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationApiOperationListResponseBodyDef> {
            return localVarFp.servicesServiceIdApiOperationsGet(serviceId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesServiceIdApiOperationLinksGet operation in PublicServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGetRequest
 */
export interface PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGet
     */
    readonly serviceId: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGet
     */
    readonly body?: object
}

/**
 * Request parameters for servicesServiceIdApiOperationsGet operation in PublicServiceSpecificationsAPIOperationsApi.
 * @export
 * @interface PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGetRequest
 */
export interface PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGet
     */
    readonly serviceId: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGet
     */
    readonly body?: object
}

/**
 * PublicServiceSpecificationsAPIOperationsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationsAPIOperationsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationsAPIOperationsApi extends BaseAPI {
    /**
     * 
     * @summary List of Service API Operation Links.
     * @param {PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsAPIOperationsApi
     */
    public servicesServiceIdApiOperationLinksGet(requestParameters: PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationLinksGetRequest, options?: any) {
        return PublicServiceSpecificationsAPIOperationsApiFp(this.configuration).servicesServiceIdApiOperationLinksGet(requestParameters.serviceId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service API Operations.
     * @param {PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsAPIOperationsApi
     */
    public servicesServiceIdApiOperationsGet(requestParameters: PublicServiceSpecificationsAPIOperationsApiServicesServiceIdApiOperationsGetRequest, options?: any) {
        return PublicServiceSpecificationsAPIOperationsApiFp(this.configuration).servicesServiceIdApiOperationsGet(requestParameters.serviceId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationsAPIToolsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationsAPIToolsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceId Service identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiToolsApiToolIdGet: async (serviceId: string, apiToolId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiToolsApiToolIdGet', 'serviceId', serviceId)
            // verify required parameter 'apiToolId' is not null or undefined
            assertParamExists('servicesServiceIdApiToolsApiToolIdGet', 'apiToolId', apiToolId)
            const localVarPath = `/services/{service-id}/api-tools/{api-tool-id}`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${"api-tool-id"}}`, encodeURIComponent(String(apiToolId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiToolsGet: async (serviceId: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdApiToolsGet', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/api-tools`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationsAPIToolsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationsAPIToolsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationsAPIToolsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceId Service identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiToolsApiToolIdGet(serviceId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiToolResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiToolsApiToolIdGet(serviceId, apiToolId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdApiToolsGet(serviceId: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationApiToolListResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdApiToolsGet(serviceId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationsAPIToolsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationsAPIToolsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationsAPIToolsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a single Service Specification API Tool.
         * @param {string} serviceId Service identifier
         * @param {string} apiToolId Service Specification API Tool identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiToolsApiToolIdGet(serviceId: string, apiToolId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationApiToolResponseBodyDef> {
            return localVarFp.servicesServiceIdApiToolsApiToolIdGet(serviceId, apiToolId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List of Service Specification API Tools.
         * @param {string} serviceId Service identifier
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdApiToolsGet(serviceId: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationApiToolListResponseBodyDef> {
            return localVarFp.servicesServiceIdApiToolsGet(serviceId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesServiceIdApiToolsApiToolIdGet operation in PublicServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGetRequest
 */
export interface PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGet
     */
    readonly serviceId: string

    /**
     * Service Specification API Tool identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGet
     */
    readonly apiToolId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGet
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGet
     */
    readonly body?: object
}

/**
 * Request parameters for servicesServiceIdApiToolsGet operation in PublicServiceSpecificationsAPIToolsApi.
 * @export
 * @interface PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGetRequest
 */
export interface PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGetRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGet
     */
    readonly serviceId: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGet
     */
    readonly body?: object
}

/**
 * PublicServiceSpecificationsAPIToolsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationsAPIToolsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationsAPIToolsApi extends BaseAPI {
    /**
     * 
     * @summary Get a single Service Specification API Tool.
     * @param {PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsAPIToolsApi
     */
    public servicesServiceIdApiToolsApiToolIdGet(requestParameters: PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsApiToolIdGetRequest, options?: any) {
        return PublicServiceSpecificationsAPIToolsApiFp(this.configuration).servicesServiceIdApiToolsApiToolIdGet(requestParameters.serviceId, requestParameters.apiToolId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List of Service Specification API Tools.
     * @param {PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsAPIToolsApi
     */
    public servicesServiceIdApiToolsGet(requestParameters: PublicServiceSpecificationsAPIToolsApiServicesServiceIdApiToolsGetRequest, options?: any) {
        return PublicServiceSpecificationsAPIToolsApiFp(this.configuration).servicesServiceIdApiToolsGet(requestParameters.serviceId, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PublicServiceSpecificationsSubscriptionsApi - axios parameter creator
 * @export
 */
export const PublicServiceSpecificationsSubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionDelete: async (serviceId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdSubscriptionDelete', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/subscription`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionPost: async (serviceId: string, aMeta?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('servicesServiceIdSubscriptionPost', 'serviceId', serviceId)
            const localVarPath = `/services/{service-id}/subscription`
                .replace(`{${"service-id"}}`, encodeURIComponent(String(serviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oAuth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oAuth", ["object_owner_conditional", "shared_object_conditional:read"], configuration)

            // authentication sessionHeaderAuth required
            await setApiKeyToObject(localVarHeaderParameter, "SESSION", configuration)

            if (aMeta !== undefined && aMeta !== null) {
                localVarHeaderParameter['A-Meta'] = String(aMeta);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/vnd.api+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicServiceSpecificationsSubscriptionsApi - functional programming interface
 * @export
 */
export const PublicServiceSpecificationsSubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicServiceSpecificationsSubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdSubscriptionDelete(serviceId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdSubscriptionDelete(serviceId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async servicesServiceIdSubscriptionPost(serviceId: string, aMeta?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicServiceSpecificationResponseBodyDef>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.servicesServiceIdSubscriptionPost(serviceId, aMeta, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublicServiceSpecificationsSubscriptionsApi - factory interface
 * @export
 */
export const PublicServiceSpecificationsSubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicServiceSpecificationsSubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @summary Ubsubscribe from a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionDelete(serviceId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdSubscriptionDelete(serviceId, aMeta, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Subscribe to a Service.
         * @param {string} serviceId Service identifier
         * @param {string} [aMeta] A-Meta is a custom header used for easy debugging on the server in case of errors. 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        servicesServiceIdSubscriptionPost(serviceId: string, aMeta?: string, body?: object, options?: any): AxiosPromise<PublicServiceSpecificationResponseBodyDef> {
            return localVarFp.servicesServiceIdSubscriptionPost(serviceId, aMeta, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for servicesServiceIdSubscriptionDelete operation in PublicServiceSpecificationsSubscriptionsApi.
 * @export
 * @interface PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest
 */
export interface PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDelete
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDelete
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDelete
     */
    readonly body?: object
}

/**
 * Request parameters for servicesServiceIdSubscriptionPost operation in PublicServiceSpecificationsSubscriptionsApi.
 * @export
 * @interface PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest
 */
export interface PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest {
    /**
     * Service identifier
     * @type {string}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPost
     */
    readonly serviceId: string

    /**
     * A-Meta is a custom header used for easy debugging on the server in case of errors. 
     * @type {string}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPost
     */
    readonly aMeta?: string

    /**
     * 
     * @type {object}
     * @memberof PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPost
     */
    readonly body?: object
}

/**
 * PublicServiceSpecificationsSubscriptionsApi - object-oriented interface
 * @export
 * @class PublicServiceSpecificationsSubscriptionsApi
 * @extends {BaseAPI}
 */
export class PublicServiceSpecificationsSubscriptionsApi extends BaseAPI {
    /**
     * 
     * @summary Ubsubscribe from a Service.
     * @param {PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsSubscriptionsApi
     */
    public servicesServiceIdSubscriptionDelete(requestParameters: PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionDeleteRequest, options?: any) {
        return PublicServiceSpecificationsSubscriptionsApiFp(this.configuration).servicesServiceIdSubscriptionDelete(requestParameters.serviceId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Subscribe to a Service.
     * @param {PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicServiceSpecificationsSubscriptionsApi
     */
    public servicesServiceIdSubscriptionPost(requestParameters: PublicServiceSpecificationsSubscriptionsApiServicesServiceIdSubscriptionPostRequest, options?: any) {
        return PublicServiceSpecificationsSubscriptionsApiFp(this.configuration).servicesServiceIdSubscriptionPost(requestParameters.serviceId, requestParameters.aMeta, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }
}


