import { ITool } from "@app/types/common.types";

import styles from "./ApiTool.module.scss";

export const ApiTool = ({ title, url, description }: ITool) => {
  return (
    <div className={styles.toolInfo} data-testid="api-tool">
      <a href={url}>{title ? title : url}</a>
      <p className={styles.toolDescription}>{description}</p>
    </div>
  );
};
