import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";

import { ServiceSpecificationsRoutesEnum } from "@app/features/services-specifications/constants/service-specifications.paths";

import CreateSpecificationForm from "@app/features/services-specifications/components/CreateSpecificationForm";

import { serviceSpecificationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import { DeserializeData, SerializeData } from "@app/utils/common";
import {
  PrivateServiceSpecificationAttributesDef,
  ServiceSpecificationSecurityLevelEnum,
} from "@app/@generated";
import useNotification from "@app/hooks/useNotification";
import { logger } from "@app/utils/logger";

const EditServiceSpecification = () => {
  const { t } = useTranslation();
  const [specification, setSpecification] =
    useState<null | PrivateServiceSpecificationAttributesDef>(null);
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const notification = useNotification();

  const { id }: { id: string } = useParams();

  const getSpecification = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdGet(
          {
            serviceSpecificationId: id,
          }
        );

      DeserializeData(data).then(res => {
        const is_public_level =
          res.securityLevel ===
          ServiceSpecificationSecurityLevelEnum.PUBLIC.toUpperCase();
        setSpecification({ ...res, is_public_level: is_public_level });
      });
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    getSpecification();
  }, [id]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (params: any) => {
    try {
      await serviceSpecificationsApi.serviceSpecificationsServiceSpecificationIdPut(
        {
          serviceSpecificationId: id,
          privateServiceSpecificationBodyDef: SerializeData(
            "service-specifications",
            {
              ...params,
              id: id,
            }
          ),
        }
      );
      notification.success(
        "Successfully saved",
        "Specification was successfully updated"
      );
    } catch (error: unknown) {
      notification.error("Error", "Specification was not be updated");
      logger.error(error);
    }
  };

  return (
    <ContentLayout
      header={{
        title: t("serviceSpecificationsEdit.title"),
      }}
      breadcrumbs={[
        {
          path: ServiceSpecificationsRoutesEnum.SERVICE_SPECIFICATIONS,
          breadcrumbName: t("serviceSpecifications.title"),
        },
        {
          breadcrumbName: specification?.title || "",
        },
        {
          breadcrumbName: t("serviceSpecifications.edit"),
        },
      ]}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && specification && (
        <CreateSpecificationForm
          initialValues={specification}
          onSubmit={handleSubmit}
        />
      )}
    </ContentLayout>
  );
};

export default EditServiceSpecification;
