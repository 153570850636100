import { useEffect, useState } from "react";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import { logger } from "@app/utils/logger";
import Button from "@app/components/Common/atoms/Button/Button";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

import styles from "./ServiceSpecificationTools.module.scss";
import { TOOLS_CATEGORY_ORDER } from "@app/constants/common";
import { ITool, Tools } from "@app/types/common.types";
import { AxiosResponse } from "axios";
import { serviceSpecificationsAPIToolsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";

import { DeserializeData, SerializeData } from "@app/utils/common";

import { ApiTool } from "src/components/ApiTools";
import { modalConfirm } from "@app/components/Common/atoms/ModalConfirm/ModalConfirm";
import {
  PrivateServiceSpecificationApiToolDefTypeEnum,
  PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest,
} from "@app/@generated";

import * as modalAction from "@app/helpers/modal.helper";
import useShowModal from "@app/hooks/useShowModal";
import ToolFormModal from "@app/components/ApiTools/ToolFormModal/ToolFormModal";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceSpecificationTools = (props: any) => {
  const { t } = useTranslation();
  const { openModal } = useShowModal();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tools, setTools] = useState<Tools<ITool> | null>(null);
  const [editableTool, setTool] = useState<ITool | null>(null);

  const { id } = props.data;
  const [isLoading, setLoadingStatus] = useState<boolean>(true);
  const [isDeleting, setDeletingStatus] = useState<string | null>(null);

  const getTools = async () => {
    try {
      setLoadingStatus(true);
      const { data }: AxiosResponse =
        await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsGet(
          {
            serviceSpecificationId: id,
          }
        );

      DeserializeData(data).then(res => {
        const tools: Tools<ITool> = {};

        res.map((tool: ITool) => {
          tools[tool.category] = tools[tool.category]
            ? [...tools[tool.category], tool]
            : [tool];
        });
        setTools(tools);
      });
      // const links = deserializeOperationsLinks(data.data);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useEffect(() => {
    getTools();
  }, [id]);

  const saveTools = async (values: ITool) => {
    try {
      const params: PrivateServiceSpecificationsAPIToolsApiServiceSpecificationsServiceSpecificationIdApiToolsPostRequest =
        {
          serviceSpecificationId: id,
          privateServiceSpecificationApiToolRequestBodyDef: SerializeData(
            PrivateServiceSpecificationApiToolDefTypeEnum.SERVICE_SPECIFICATION_API_TOOLS,
            {
              ...values,
              id: values.id,
            }
          ),
        };

      if (values.id) {
        await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdPut(
          {
            apiToolId: values.id,
            ...params,
          }
        );
      } else {
        await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsPost(
          {
            ...params,
          }
        );
      }
    } catch (error: unknown) {
      logger.error(error);
    }
  };

  const handleFormControl = () => {
    openModal(
      modalAction.custom({
        customAction: "create_tool",
      })
    );
  };

  const handleSubmit = async (values: ITool, callback: () => void) => {
    await saveTools(values);
    await getTools();
    setTool(null);
    if (callback) callback();
  };

  const deleteTool = async (apiToolId: string) => {
    setDeletingStatus(apiToolId);
    try {
      await serviceSpecificationsAPIToolsApi.serviceSpecificationsServiceSpecificationIdApiToolsApiToolIdDelete(
        {
          serviceSpecificationId: id,
          apiToolId: apiToolId,
        }
      );
      await getTools();
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setDeletingStatus(null);
    }
  };

  const handleEdit = (tool: ITool) => {
    setTool(tool);
    openModal(
      modalAction.custom({
        customAction: "create_tool",
        id: tool.id,
      })
    );
  };

  const handleDelete = async (apiToolId: string) => {
    modalConfirm(t, {
      title: t("default.deleteTitle"),
      content: t("default.tools.confirmText"),
      okText: t("default.delete"),
      onOk: () => {
        deleteTool(apiToolId);
      },
    });
  };

  return (
    <div>
      <div id="wrap-api-tools" style={{ position: "relative" }}>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div>
            <div className={styles.addTools}>
              <Button onClick={handleFormControl}>
                <PlusOutlined />{" "}
                <span>{t("serviceSpecificationsAPIToolsAdd.title")}</span>
              </Button>
            </div>

            {tools &&
              TOOLS_CATEGORY_ORDER.map(group =>
                tools[group] ? (
                  <div className={styles.tools}>
                    <div className={styles.toolGroup}>
                      <h6 className={styles.toolGroupTitle}>
                        {t(`default.tools.${group}`)}
                      </h6>

                      {tools[group]?.map((tool: ITool) => (
                        <div className={styles.tool}>
                          <ApiTool {...tool} />
                          <div>
                            <Button
                              icon
                              type="primary"
                              className={styles.toolControls}
                              onClick={() => handleEdit(tool)}
                            >
                              <EditOutlined />
                            </Button>
                            <Button
                              icon
                              type="primary"
                              danger
                              className={styles.toolControls}
                              onClick={() => handleDelete(tool.id)}
                              loading={isDeleting === tool.id}
                            >
                              <DeleteOutlined />
                            </Button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null
              )}
          </div>
        )}
        <ToolFormModal
          onConfirm={handleSubmit}
          initialValues={editableTool ? editableTool : { title: "" }}
          onClose={() => setTool(null)}
        />
      </div>
    </div>
  );
};

export default ServiceSpecificationTools;
