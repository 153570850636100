import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getServices } from "@app/features/services/store/services.slice";
import { useAppDispatch, useAppSelector } from "@app/store/store";

import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import ServicesList from "@app/components/ServicesList";
import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";

import ServiceApi from "@app/features/services/components/ServiceApi";
import ServiceEvents from "@app/features/services/components/ServiceEvents";
import ServiceEnvironments from "@app/features/services/components/ServiceEnvironments";
import ServiceSchema from "@app/features/services/components/ServiceSchema";
import { Panes } from "@app/types/common.types";
import ServiceTools from "@app/features/services/components/ServiceTools";
import ServiceOperations from "@app/features/services/components/ServiceOperations";

function Services() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { services, isLoading } = useAppSelector(state => state.services);

  const allPanes: Panes = {
    api: {
      type: t("servicesApi.title"),
      panTitle: (title: string) => `${t("servicesApi.title")} - ${title}`,
      component: ServiceApi,
      closable: true,
    },
    events: {
      type: t("servicesEvents.title"),
      panTitle: (title: string) => `${t("servicesEvents.title")} - ${title}`,
      component: ServiceEvents,
      closable: true,
    },
    tools: {
      type: t("servicesTools.title"),
      panTitle: (title: string) => `${t("servicesTools.title")} - ${title}`,
      component: ServiceTools,
      closable: true,
    },
    operations: {
      type: t("servicesOperationsTools.title"),
      panTitle: (title: string) =>
        `${t("servicesOperationsTools.title")} - ${title}`,
      component: ServiceOperations,
      closable: true,
    },
    environments: {
      type: t("servicesEnvironments.title"),
      panTitle: (title: string) =>
        `${t("servicesEnvironments.title")} - ${title}`,
      component: ServiceEnvironments,
      closable: true,
    },
    schema: {
      type: t("servicesSchema.title"),
      panTitle: (title: string) => `${t("servicesSchema.title")} - ${title}`,
      component: ServiceSchema,
      closable: true,
    },
  };

  useEffect(() => {
    dispatch(getServices({}));
  }, [dispatch]);

  return (
    <ContentLayout
      noContentStyle
      header={{
        title: t("servicesScreen.title"),
      }}
      title={t("servicesScreen.title")}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <ServicesList
          title={t("servicesScreen.title")}
          allPanes={allPanes}
          services={services}
        />
      )}
    </ContentLayout>
  );
}

export default Services;
