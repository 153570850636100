import { useTranslation } from "react-i18next";
import { useMount } from "react-use";
import { Switch } from "antd";

import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";

import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import { useState } from "react";
import { AxiosError } from "axios";

import { logger } from "@app/utils/logger";
import { accountsApi } from "@app/features/profile/api/profile.api";
import { Content } from "antd/lib/layout/layout";

// Use if it more than 2 Profile pages
// import ProfileSidebar from "@app/features/profile/components/ProfileSidebar/ProfileSidebar";
import { Space, Table } from "antd";
import { useAppDispatch, useAppSelector } from "@app/store/store";
import { IAccount } from "@app/features/profile/profile.types";

import { ItemModalEnum } from "@app/constants/route.constants";
import Button from "@app/components/Common/atoms/Button/Button";
import DeleteConfirmationModal, {
  DeletePayloadDef,
} from "@app/components/DeleteConfirmationModal/DeleteConfirmationModal";
import useShowModal from "@app/hooks/useShowModal";
import * as modalAction from "@app/helpers/modal.helper";
import {
  getMyAccounts,
  ProfileRoutes,
  setCurrentAccount,
  setDefaultAccount,
} from "@app/features/profile";
import { saveAccountToken } from "@app/helpers/auth.helpers";

import AccountFormModal, {
  DataPayloadDef,
} from "@app/features/profile/components/AccountFormModal/AccountFormModal";

import styles from "./ManageAccounts.module.scss";
import { PrivateAccountsApiPrivateAccountsPostRequest } from "@app/@generated";
import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

function ManageAccounts() {
  const { t } = useTranslation();
  // eslint-disable-next-line

  const [errors, setErrorsAccounts] = useState<Record<string, string>>({});
  const { currentAccount, isLoading, myAccounts, defaultAccount } =
    useAppSelector(state => state.profile);

  const [isUpdating, setUpdatingStatus] = useState<boolean>(false);
  const [editableAccount, setAccount] = useState<IAccount | null>(null);

  const { openModal } = useShowModal();
  const dispatch = useAppDispatch();
  const getAccounts = async () => {
    dispatch(getMyAccounts({}));
  };

  const CreateAccount = async (data: DataPayloadDef, callback: () => void) => {
    const values: PrivateAccountsApiPrivateAccountsPostRequest = {
      privateAccountBodyDef: {
        data: {
          type: "private-accounts",
          attributes: {
            ...data,
          },
        },
      },
    };
    try {
      setUpdatingStatus(true);
      if (data.id) {
        await accountsApi.privateAccountsAccountIdPut({
          accountId: data.id,
          ...values,
        });
      } else {
        await accountsApi.privateAccountsPost(values);
      }
      getAccounts();
      callback();
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err?.response?.status === 422) {
        setErrorsAccounts({ name: t("default.unicName") });
      }
      logger.error(error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  const DeleteAccount = async (
    data: DeletePayloadDef,
    callback: () => void
  ) => {
    const { id } = data;
    try {
      setUpdatingStatus(true);
      if (currentAccount === id) {
        dispatch(setDefaultAccount());
      }
      await accountsApi.privateAccountsAccountIdDelete({
        accountId: id,
      });
      getAccounts();
      callback();
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setUpdatingStatus(false);
    }
  };

  const handleDelete = (record: IAccount) => {
    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.DELETE,
        id: record.id,
        name: record.title,
      })
    );
  };

  const handleCreate = () => {
    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.CREATE,
      })
    );
  };

  const handleEdit = (record: IAccount) => {
    setAccount(record);
    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.CREATE,
        id: record.id,
      })
    );
  };

  useMount(() => {
    getAccounts();
  });

  const onHandleDefault = (checked: boolean, record: IAccount) => {
    if (checked && record.id) {
      saveAccountToken(record.id);
      dispatch(setCurrentAccount(record.id));
    }
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Role",
      dataIndex: "userRole",
      key: "userRole",
    },
    {
      title: "Current",
      key: "action",
      render: (_: unknown, record: IAccount) => (
        <Space size="middle">
          <Switch
            checked={currentAccount === record.id}
            onChange={checked => onHandleDefault(checked, record)}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 210,
      render: (_: unknown, record: IAccount) =>
        record?.id ? (
          <Space size="middle">
            <Button
              icon
              title={t("profile.addMember")}
              type="primary"
              to={ProfileRoutes.PROFILE_ACCOUNT_MEMBERS(record.id)}
            >
              <UsergroupAddOutlined />
            </Button>
            <Button icon type="primary" onClick={() => handleEdit(record)}>
              <EditOutlined />
            </Button>
            {defaultAccount !== record.id && (
              <Button
                icon
                type="primary"
                danger
                className={styles.toolControls}
                onClick={() => handleDelete(record)}
              >
                <DeleteOutlined />
              </Button>
            )}
          </Space>
        ) : null,
    },
  ];

  return (
    <ContentLayout
      noContentStyle
      header={{
        title: t("profile.title"),
      }}
    >
      {/*<ContentLayout noContentStyle sidebar={<ProfileSidebar />}>*/}
      <ContentLayout noContentStyle>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: "0",
            minHeight: 280,
            background: "#fff",
          }}
        >
          <div className={styles.subTitleGroup}>
            <h2 className={styles.subTitle}>{t("profile.manageAccounts")}</h2>
            <Button
              type="primary"
              className={styles.button}
              onClick={handleCreate}
            >
              {t("profile.AddAccount")}
            </Button>
          </div>

          {!isLoading && myAccounts.length !== 0 && (
            <Table
              columns={columns}
              dataSource={myAccounts}
              pagination={{ pageSize: 20, hideOnSinglePage: true }}
            />
          )}
          {(isLoading || isUpdating) && <LoadingSpinner />}
        </Content>
        <DeleteConfirmationModal onConfirm={DeleteAccount} fieldName="title" />
        <AccountFormModal
          onConfirm={CreateAccount}
          errors={errors}
          initialValues={
            editableAccount
              ? editableAccount
              : { title: "", name: "", description: "" }
          }
          onClose={() => setAccount(null)}
        />
      </ContentLayout>
    </ContentLayout>
  );
}

export default ManageAccounts;
