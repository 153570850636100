import { useTranslation } from "react-i18next";
import { useMount } from "react-use";

import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";

import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";

import { useState } from "react";

import { logger } from "@app/utils/logger";
import {
  accountsApi,
  accountsUsersApi,
} from "@app/features/profile/api/profile.api";
import { Content } from "antd/lib/layout/layout";

// Use if it more than 2 Profile pages
// import ProfileSidebar from "@app/features/profile/components/ProfileSidebar/ProfileSidebar";
import { Select, Space, Table } from "antd";
import { IAccount, IMember } from "@app/features/profile/profile.types";

import { ItemModalEnum } from "@app/constants/route.constants";
import Button from "@app/components/Common/atoms/Button/Button";
import DeleteConfirmationModal, {
  DeletePayloadDef,
} from "@app/components/DeleteConfirmationModal/DeleteConfirmationModal";
import useShowModal from "@app/hooks/useShowModal";
import * as modalAction from "@app/helpers/modal.helper";

import styles from "./ManageAccountMembers.module.scss";
import { DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { DeserializeData } from "@app/utils/common";
import {
  MEMBER_ROLES,
  MEMBER_ROLES_SELECT,
} from "@app/features/profile/constants/common";
import {
  PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest,
  UserIdentifierObjectDefTypeEnum,
} from "@app/@generated";
import MemberFormModal from "@app/features/profile/components/MemberFormModal";

function ManageAccountMembers() {
  const { t } = useTranslation();
  // eslint-disable-next-line
  const { id }: { id: string } = useParams();
  const [isLoading, setLoadingStatus] = useState<boolean>(false);
  const [account, setAccount] = useState<IAccount | null>(null);

  const [members, setMembers] = useState<IMember[] | null>(null);

  const { openModal } = useShowModal();

  const getMembers = async () => {
    try {
      setLoadingStatus(true);
      const response = await accountsUsersApi.privateAccountsAccountIdUsersGet({
        accountId: id,
      });
      const accountData = await DeserializeData(response.data);
      setMembers(accountData);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const getAccount = async () => {
    try {
      setLoadingStatus(true);
      const response = await accountsApi.privateAccountsAccountIdGet({
        accountId: id,
      });
      const accountData = await DeserializeData(response.data);
      setAccount(accountData);
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const DeleteMember = async (data: DeletePayloadDef, callback: () => void) => {
    try {
      setLoadingStatus(true);
      await accountsUsersApi.privateAccountsAccountIdUsersUserIdDelete({
        accountId: id,
        userId: data.id,
      });
      getMembers();
      callback();
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleDelete = (record: IMember) => {
    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.DELETE,
        id: record.id,
        name: record.email,
      })
    );
  };

  const handleCreate = () => {
    openModal(
      modalAction.custom({
        customAction: ItemModalEnum.CREATE,
      })
    );
  };

  const handleChangeRole = async (record: IMember, value: string) => {
    handleCreateMember({ ...record, role: value });
  };

  //eslint-disable-next-line
  const handleCreateMember = async (data: IMember, callback?: () => void) => {
    const values: PrivateAccountUsersApiPrivateAccountsAccountIdUsersPostRequest =
      {
        accountId: id,
        inlineObjectDef: {
          data: {
            type: UserIdentifierObjectDefTypeEnum.USERS,
            attributes: {
              ...data,
            },
          },
        },
      };

    try {
      setLoadingStatus(true);
      if (data.id) {
        //await accountsUsersApi.privateAccountsAccountIdUsersPut({...values, role: value});
      } else {
        await accountsUsersApi.privateAccountsAccountIdUsersPost(values);
      }
      getMembers();
      if (callback) callback();
    } catch (error: unknown) {
      logger.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  useMount(() => {
    getAccount();
    getMembers();
  });

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 140,
      render: (_: unknown, record: IMember) =>
        record?.id ? (
          <Space size="middle">
            <Select
              defaultValue={record.role}
              style={{ width: 140 }}
              className="accountSelect"
              onChange={value => handleChangeRole(record, value)}
              options={MEMBER_ROLES_SELECT}
              disabled
            />
          </Space>
        ) : null,
    },
    {
      title: "Action",
      key: "action",
      width: 110,
      render: (_: unknown, record: IMember) =>
        record?.id ? (
          <Space size="middle">
            <Button
              icon
              type="primary"
              danger
              className={styles.toolControls}
              onClick={() => handleDelete(record)}
            >
              <DeleteOutlined />
            </Button>
          </Space>
        ) : null,
    },
  ];

  return (
    <ContentLayout
      noContentStyle
      header={{
        title: account?.title,
      }}
    >
      {/*<ContentLayout noContentStyle sidebar={<ProfileSidebar />}>*/}
      <ContentLayout noContentStyle>
        <Content
          className="site-layout-background"
          style={{
            padding: 24,
            margin: "0",
            minHeight: 280,
            background: "#fff",
          }}
        >
          <div className={styles.subTitleGroup}>
            <h2 className={styles.subTitle}>{t("profile.addMemberTitle")}</h2>
            <Button
              type="primary"
              className={styles.button}
              onClick={handleCreate}
            >
              {t("profile.addMemberButton")}
            </Button>
          </div>

          {!isLoading && members && members.length !== 0 && (
            <Table
              columns={columns}
              dataSource={members}
              pagination={{ pageSize: 20, hideOnSinglePage: true }}
            />
          )}
          {isLoading && <LoadingSpinner />}
        </Content>
        <DeleteConfirmationModal onConfirm={DeleteMember} />
        <MemberFormModal
          onConfirm={handleCreateMember}
          initialValues={{ email: "", role: MEMBER_ROLES.MAINTAINER }}
          onClose={() => setAccount(null)}
          members={members}
          accountName={account?.title}
        />
      </ContentLayout>
    </ContentLayout>
  );
}

export default ManageAccountMembers;
