import { ITool, IToolsPrivate, IToolsPublic } from "@app/types/common.types";

import { useTranslation } from "react-i18next";
import { ApiTool } from "@app/components/ApiTools";

import styles from "./ApiTools.module.scss";

export const ApiTools = ({
  tools,
  group,
}: {
  tools: IToolsPrivate | IToolsPublic;
  group: string;
}) => {
  const { t } = useTranslation();

  return tools[group] ? (
    <div className={styles.tools}>
      <div className={styles.toolGroup}>
        <h6 className={styles.toolGroupTitle}>{t(`default.tools.${group}`)}</h6>

        {tools[group]?.map((tool: ITool) => (
          <div className={styles.tool}>
            <ApiTool {...tool} />
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
