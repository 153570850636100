import React, { memo, useCallback, useState } from "react";

import { Button, Col, Input, Row, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

import { FormItem, useForm } from "@app/components/Common/atoms/Form/Form";
import FormModal from "@app/components/Common/atoms/FormModal/FormModal";

import useShowModal from "@app/hooks/useShowModal";

import styles from "./MemberFormModal.module.scss";
import { useAppSelector } from "@app/store/store";
import { IMember } from "@app/features/profile/profile.types";
import {
  MEMBER_ROLES,
  MEMBER_ROLES_SELECT,
} from "@app/features/profile/constants/common";

export const CUSTOM_ACTION_CREATE = "create";

interface MemberFormModalProps {
  onConfirm: (data: DataPayloadDef, callback?: () => void) => void;
  loading?: boolean;
  errors?: Record<string, string>;
  initialValues?: Record<string, string> | IMember;
  onClose: () => void;
  members: IMember[] | null;
  accountName?: string;
}

export interface DataPayloadDef {
  email: string;
  role: string;
  id?: string;
}

const MemberFormModal = ({
  onConfirm,
  loading,
  errors,
  initialValues,
  members,
  accountName,
  onClose,
}: MemberFormModalProps) => {
  const { isShowModal, closeModal } = useShowModal({
    customActionType: CUSTOM_ACTION_CREATE,
  });
  const { t } = useTranslation();
  const { modal } = useAppSelector(state => state.ui);
  const [memberErrror, setMemberError] = useState<string>("");

  const [form] = useForm();

  const handleClose = useCallback(() => {
    form.resetFields();
    closeModal();
    if (onClose) onClose();
  }, [closeModal, form]);

  const handleFinish = async (values: DataPayloadDef) => {
    onConfirm(
      { ...values, ...(modal?.entryId && { id: modal.entryId }) },
      handleClose
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (members) {
      const member = members.find(member => member.email === value);
      if (member) {
        setMemberError("Member already exists");
      }
    }
  };

  return (
    <FormModal
      form={form}
      title={t("profile.addMemberButton")}
      visible={isShowModal}
      onClose={handleClose}
      values={initialValues as Record<string, string>}
      onFinish={handleFinish}
      destroyOnClose
      errors={{ ...errors, ...(memberErrror && { email: memberErrror }) }}
      footer={
        <Row justify="end">
          <Col>
            <Space>
              <Button onClick={handleClose}>{t("default.cancelTitle")}</Button>
              <Button
                type="primary"
                disabled={loading}
                className={styles.submitButton}
                htmlType="submit"
                loading={loading}
              >
                {t("profile.addMemberButton")}
              </Button>
            </Space>
          </Col>
        </Row>
      }
    >
      <p>
        You're inviting member to the <b>{accountName}</b> account.
      </p>
      <Row gutter={20}>
        <Col span={24}>
          <FormItem
            name="email"
            label={t("Email")}
            rules={[
              {
                required: true,
                message: "Invalid email format",
                pattern: /\S+@\S+\.\S+/,
              },
            ]}
          >
            <Input onBlur={handleChange} />
          </FormItem>
        </Col>
        <Col span={24}>
          <FormItem name="role" label={t("Role")}>
            <Select
              defaultValue={MEMBER_ROLES.MAINTAINER}
              className="accountSelect"
              options={MEMBER_ROLES_SELECT}
            />
          </FormItem>
        </Col>
      </Row>
    </FormModal>
  );
};

export default memo(MemberFormModal);
