import {
  MyAccountsApi,
  PrivateAccountsApi,
  PrivateAccountUsersApi,
} from "@app/@generated";
import { axiosInstance } from "@app/api/api";
import { apiClientConfiguration } from "@app/constants/api.constants";

const myAccountsApi = new MyAccountsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

const accountsApi = new PrivateAccountsApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);

const accountsUsersApi = new PrivateAccountUsersApi(
  apiClientConfiguration,
  undefined,
  axiosInstance
);
export { myAccountsApi, accountsApi, accountsUsersApi };
