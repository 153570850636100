import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import yaml from "yaml";
import { ServiceSpecificationSecurityLevelEnum } from "@app/@generated";

import { Button, Input, Row, Col, Checkbox } from "antd";

import Form, { FormItem } from "@app/components/Common/atoms/Form";
import ApiPreview from "@app/components/ApiPreview";

import { API_EDITOR_MODE } from "@app/constants/common";

import styles from "./CreateSpecificationForm.module.scss";
import { useForm } from "@app/components/Common/atoms/Form/Form";
import { convertToSlug } from "@app/helpers/util.helper";

type CreateSpecificationFormProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialValues?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (params: any) => void;
};
const CreateSpecificationForm = ({
  initialValues,
  onSubmit,
}: CreateSpecificationFormProps) => {
  const [apiSpec, setApiSpec] = useState(
    initialValues && initialValues.apiSpecification
      ? yaml.stringify(initialValues.apiSpecification)
      : ""
  );

  const { t } = useTranslation();
  const [formInstance] = useForm();

  const { TextArea } = Input;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: any) => {
    const { is_public_level, ...otherValues } = values;

    const params = {
      ...otherValues,
      apiSpecification: yaml.parse(apiSpec) || {},
      securityLevel: is_public_level
        ? ServiceSpecificationSecurityLevelEnum.PUBLIC.toUpperCase()
        : ServiceSpecificationSecurityLevelEnum.PRIVATE.toUpperCase(),
    };

    onSubmit(params);
  };

  const handleChangeApiSpec = (spec: string) => {
    setApiSpec(spec);
  };

  const handleChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (formInstance.getFieldValue("name") === "") {
      formInstance.setFieldValue("name", convertToSlug(value));
    }
  };

  return (
    <>
      <Form
        values={initialValues}
        onFinish={handleSubmit}
        className={styles.form}
        form={formInstance}
        id="create-specification-form"
      >
        <Row gutter={20}>
          <Col span={12} className="gutter-row">
            <FormItem
              name="title"
              label={t("Title")}
              rules={[
                {
                  required: true,
                  message: t("This field is required"),
                },
              ]}
            >
              <Input
                placeholder={t("My awesome API service")}
                onBlur={handleChangeTitle}
              />
            </FormItem>
          </Col>
          <Col span={12} className="gutter-row">
            <FormItem
              name="name"
              label={t("Slug")}
              rules={[
                {
                  required: true,
                  message: t("This field is required"),
                },
                {
                  pattern: new RegExp(/^[a-z0-9-_]*$/),
                  message:
                    "Slug can only contain lowercase letters, numbers, hyphens (-), and underscores (_)",
                },
              ]}
            >
              <Input placeholder={t("my-awesome-api-service")} type="text" />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24} className="gutter-row">
            <FormItem
              name="description"
              label={t("default.description")}
              rules={[
                {
                  required: true,
                  message: t("This field is required"),
                },
              ]}
            >
              <TextArea rows={6} placeholder={t("default.description")} />
            </FormItem>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24} className="gutter-row">
            <FormItem
              name="is_public_level"
              valuePropName="checked"
              label={t("default.privacy")}
            >
              <Checkbox>{t("default.publicTitle")}</Checkbox>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <Row gutter={20}>
        <Col span={24} className="gutter-row">
          <div className="ant-col ant-form-item-label">
            <label>{t("default.apiSpec")}</label>
          </div>
          <ApiPreview
            isEditable
            apiSpecification={apiSpec}
            defaultMode={API_EDITOR_MODE.EDITOR}
            onChange={handleChangeApiSpec}
          />
        </Col>
      </Row>

      <div className={styles.wrapButtons}>
        <Button
          href="/service-specifications"
          htmlType="button"
          className={styles.button}
        >
          {t("default.cancelTitle")}
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          form="create-specification-form"
          className={styles.button}
        >
          {t("default.saveTitle")}
        </Button>
      </div>
    </>
  );
};

export default CreateSpecificationForm;
