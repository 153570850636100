import { RouteItemDef } from "@app/types/route.types";

import { ProfileRoutesEnum } from "../constants/profile.paths";
import { PermissionEnum } from "@app/features/permissions/constants/permissions.scopes";
import { SettingOutlined } from "@ant-design/icons";
import ManageAccounts from "@app/features/profile/screens/ManageAccounts";
import ManageAccountMembers from "@app/features/profile/screens/ManageAccountMembers";

export const PROFILE: RouteItemDef = {
  id: "profile",
  path: ProfileRoutesEnum.PROFILE,
  title: "profile.title",
  icon: SettingOutlined,
  hideInNavigation: true,
  component: ManageAccounts,
  // permissions: [PermissionEnum.SERVICE_SPECIFICATIONS_LIST],
};

export const PROFILE_ACCOUNTS: RouteItemDef = {
  id: "profile-accouts",
  path: ProfileRoutesEnum.PROFILE_ACCOUNTS,
  title: "profile.title",
  hideInNavigation: true,
  component: ManageAccounts,
  permissions: [PermissionEnum.ACCOUNTS_LIST, PermissionEnum.ACCOUNTS_CREATE],
};

export const PROFILE_ACCOUNT_MEMBERS: RouteItemDef = {
  id: "profile-accout-members",
  path: ProfileRoutesEnum.PROFILE_ACCOUNT_MEMBERS,
  title: "profile.title",
  hideInNavigation: true,
  component: ManageAccountMembers,
  permissions: [PermissionEnum.ACCOUNTS_EDIT],
};

export const PROFILE_ROUTES = [
  PROFILE_ACCOUNT_MEMBERS,
  PROFILE_ACCOUNTS,
  PROFILE,
];
