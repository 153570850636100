import { RefObject, useCallback, useEffect } from "react";

import { Form as AntdForm } from "antd";
import { FormProps as AntdFormProps, FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { NamePath, StoreValue } from "antd/lib/form/interface";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import { usePrevious } from "react-use";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValues = any;

export type FormErrors = Record<string, string | string[]> | undefined;

export interface FieldDataDef {
  errors: string[];
  name: NamePath;
  value: StoreValue;
}

export interface FormProps extends AntdFormProps {
  values?: FormValues;
  formRef?: RefObject<FormInstance>;
  errors?: FormErrors | null;
  children: React.ReactNode;
  form: FormInstance;
}

const Form = ({
  values = undefined,
  children,
  formRef = undefined,
  errors = undefined,
  form,
  ...props
}: FormProps) => {
  const bindValues = useCallback(() => {
    form.resetFields();
  }, [form]);

  const getErrors = (error: string | string[]) => {
    return Array.isArray(error) ? error : [error];
  };

  const bindErrors = useCallback(() => {
    if (form) {
      const { getFieldInstance, setFields, getFieldValue } = form;
      if (!_isEmpty(errors)) {
        const payload: FieldDataDef[] = [];
        if (errors) {
          Object.keys(errors).forEach(key => {
            const field = getFieldInstance(key);
            if (errors[key] && field) {
              payload.push({
                name: key,
                value: getFieldValue(key),
                errors: getErrors(errors[key]),
              });
            }
          });
        }
        setFields(payload);
      }
    }
  }, [form, errors]);

  const prevValues = usePrevious(values);

  useEffect(() => {
    if (!_isEqual(prevValues, values)) {
      bindValues();
    }
  }, [bindValues, values, prevValues]);

  useEffect(() => {
    bindErrors();
  }, [bindErrors, errors]);

  return (
    <AntdForm
      layout="vertical"
      requiredMark="optional"
      {...props}
      form={form}
      ref={formRef}
      initialValues={values}
    >
      {children}
    </AntdForm>
  );
};

export const { Item: FormItem, List } = AntdForm;

export default Form;

export { useForm };
