export const PROFILE_TRANSLATIONS_EN = {
  // Common
  "profile.title": "Profile",
  // Account
  "profile.manageAccounts": "Manage Accounts",
  "profile.AddAccountTitle": "Create Account",
  "profile.AddAccount": "Create Account",
  "profile.EditAccountTitle": "Edit Account",
  "profile.addMember": "Manage Members",
  "profile.addMemberButton": "Add Member",
  "profile.addMemberTitle": "Members",
};
