export const ProfileRoutes = {
  PROFILE: "/profile",
  PROFILE_ACCOUNTS: "/profile/accounts",
  PROFILE_ACCOUNT_MEMBERS: (id: string) => `/profile/accounts/${id}/members`,
};

export enum ProfileRoutesEnum {
  PROFILE = "/profile",
  PROFILE_ACCOUNTS = "/profile/accounts",
  PROFILE_ACCOUNT_MEMBERS = "/profile/accounts/:id/members",
}
