import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { MyAccountsApiMyAccountsGetRequest } from "@app/@generated";

import { ErrorDef } from "@app/types/api.types";
import { myAccountsApi } from "@app/features/profile/api/profile.api";
import { DeserializeData } from "@app/utils/common";
import { IAccount } from "@app/features/profile/profile.types";
import { getDefaultAccountID } from "@app/features/profile";

export const PROFILE_KEY = "profile";

interface SliceState {
  myAccounts: IAccount[] | [];
  currentAccount: string | null;
  defaultAccount: string | null;
  isLoaded: boolean;
  isLoading: boolean;
  error: ErrorDef | null;
}

const initialState: SliceState = {
  myAccounts: [],
  currentAccount: null,
  defaultAccount: null,
  isLoaded: false,
  isLoading: false,
  error: null,
};

export const getMyAccounts = createAsyncThunk(
  "profile/getMyAccount",
  async (params: MyAccountsApiMyAccountsGetRequest, { rejectWithValue }) => {
    try {
      const response = await myAccountsApi.myAccountsGet({});
      const myAccounts = await DeserializeData(response.data);
      return myAccounts;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const profileSlice = createSlice({
  name: PROFILE_KEY,
  initialState,
  reducers: {
    setDefaultAccount: state => {
      state.currentAccount = getDefaultAccountID(state.myAccounts);
    },
    clearMyAccounts: state => {
      state.myAccounts = [];
      state.currentAccount = null;
      state.isLoaded = false;
      state.isLoading = false;
      state.error = null;
    },
    setCurrentAccount: (state, action) => {
      state.currentAccount = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getMyAccounts.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.isLoaded = false;
      state.myAccounts = [];
      state.defaultAccount = null;
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(
      getMyAccounts.fulfilled,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (state, action: any) => {
        state.isLoading = false;
        state.isLoaded = true;
        const accounts = (action.payload as IAccount[]) ?? [];
        state.myAccounts = accounts;
        const defaultAccountID = getDefaultAccountID(accounts);
        state.defaultAccount = defaultAccountID;
        if (action.payload.length > 0 && !state.currentAccount) {
          state.currentAccount = defaultAccountID;
        }
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    builder.addCase(getMyAccounts.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isLoaded = false;
      state.error = action.payload as ErrorDef;
    });
  },
});

export const { clearMyAccounts, setCurrentAccount, setDefaultAccount } =
  profileSlice.actions;

export const profileReducer = profileSlice.reducer;
