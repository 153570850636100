import {useTranslation} from "react-i18next";
import {useMount} from "react-use";

import Button from "@app/components/Common/atoms/Button/Button";
import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";
import {Permission, PermissionEnum,} from "@app/features/permissions/permissions";
import {getServiceSpecifications} from "@app/features/services-specifications/store/service-specifications.slice";
import {useAppDispatch, useAppSelector} from "@app/store/store";
import LoadingSpinner from "@app/components/Common/atoms/LoadingSpinner/LoadingSpinner";
import ServicesList from "@app/components/ServicesList";
import SpecificationPreview from "@app/components/SpecificationPreview";

import ServiceSpecificationControls
  from "@app/features/services-specifications/components/ServiceSpecificationControls";
import ServiceSpecificationEvents from "@app/features/services-specifications/components/ServiceSpecificationEvents";
import ServiceSpecificationEnvironments
  from "@app/features/services-specifications/components/ServiceSpecificationEnvironments";
import {SERVICES_SPECIFICATIONS_CREATE} from "@app/features/services-specifications";
import EmptyState from "@app/components/EmptyState";
import ServiceSpecificationJourney from "@app/features/services-specifications/components/ServiceSpecificationJourney";
import {Panes} from "@app/types/common.types";
import ServiceSpecificationTools from "@app/features/services-specifications/components/ServiceSpecificationTools";
import ServiceSpecificationOperations
  from "@app/features/services-specifications/components/ServiceSpecificationOperations/ServiceSpecificationOperations";
import {useEffect} from "react";

function ServiceSpecifications() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { isLoading, serviceSpecifications } = useAppSelector(
    state => state.serviceSpecifications
  );

  const { currentAccount } = useAppSelector(state => state.profile);

  useMount(() => {
    dispatch(getServiceSpecifications({}));
  });

  useEffect(() => {
    if (currentAccount) {
      dispatch(getServiceSpecifications({}));
    }
  }, [currentAccount]);

  const allPanes: Panes = {
    api: {
      type: t("servicesApi.title"),
      panTitle: (title: string) => `${t("servicesApi.title")} - ${title}`,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: (props: any) => (
        <SpecificationPreview
          isEditable={false}
          constrols={<ServiceSpecificationControls data={props.data} />}
          {...props}
        />
      ),
      closable: true,
    },
    events: {
      type: t("servicesEvents.title"),
      panTitle: (title: string) => `${t("servicesEvents.title")} - ${title}`,
      component: ServiceSpecificationEvents,
      closable: true,
    },
    tools: {
      type: t("serviceSpecificationsAPITools.title"),
      panTitle: (title: string) =>
        `${t("serviceSpecificationsAPITools.title")} - ${title}`,
      component: ServiceSpecificationTools,
      closable: true,
    },
    operations: {
      type: t("serviceSpecificationsAPIOperationsTools.title"),
      panTitle: (title: string) =>
        `${t("serviceSpecificationsAPIOperationsTools.title")} - ${title}`,
      component: ServiceSpecificationOperations,
      closable: true,
    },
    environments: {
      type: t("servicesEnvironments.title"),
      panTitle: (title: string) =>
        `${t("servicesEnvironments.title")} - ${title}`,
      component: ServiceSpecificationEnvironments,
      closable: true,
    },
    schema: {
      type: t("servicesSchema.title"),
      panTitle: (title: string) => `${t("servicesSchema.title")} - ${title}`,
      component: ServiceSpecificationJourney,
      closable: true,
    },
  };

  return (
    <ContentLayout
      noContentStyle
      header={{
        title: t("serviceSpecifications.title"),
        extra: [
          <Permission
            requiredPermissions={[PermissionEnum.SERVICE_SPECIFICATIONS_EDIT]}
            key="edit"
          >
            <Button type="primary" to={SERVICES_SPECIFICATIONS_CREATE.path}>
              {t("serviceSpecifications.addNew")}
            </Button>
          </Permission>,
        ],
      }}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && serviceSpecifications.length !== 0 && (
        <ServicesList
          title={t("serviceSpecifications.title")}
          allPanes={allPanes}
          services={serviceSpecifications}
        />
      )}
      {!isLoading && serviceSpecifications.length === 0 && (
        <EmptyState
            title="No API Specifications yet"
            text={
            <>
              Create a new API Specification
              <br/> to use by yourself, share with your team or publicly.
            </>
          }
        />
      )}
    </ContentLayout>
  );
}

export default ServiceSpecifications;
