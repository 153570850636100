import { IAccount } from "@app/features/profile/profile.types";

export const getAccountOptions = (
  accounts: IAccount[],
  current?: string | null
) => {
  const mappedData = accounts.map((account: IAccount) => ({
    value: account.id,
    label: account.title,
    disabled: account.id === current,
  }));
  return mappedData;
};

export const getDefaultAccountID = (accounts: IAccount[]): string | null => {
  if (accounts.length === 0) return null;

  const defaultAccount = accounts.find(account => account.isDefault);
  return (defaultAccount ? defaultAccount.id : accounts[0].id) || null;
};
