import { UserRoleEnum } from "@app/constants/common";

/**
 * All permission scopes.
 * The scopes shall be aligned with backend
 */
export enum PermissionEnum {
  SERVICES_LIST = "app.services.list",
  SERVICES_READ = "app.services.read",
  SERVICES_SUBSCRIBE = "app.services.subscribe",
  SERVICES_UNSUBSCRIBE = "app.services.unsubscribe",
  SERVICE_SPECIFICATIONS_DELETE = "app.service_specifications.delete",
  SERVICE_SPECIFICATIONS_EDIT = "app.service_specifications.edit",
  SERVICE_SPECIFICATIONS_CREATE = "app.service_specifications.create",
  SERVICE_SPECIFICATIONS_LIST = "app.service_specifications.list",
  SERVICE_SPECIFICATIONS_READ = "app.service_specifications.read",
  SERVICE_SPECIFICATIONS_API = "app.service_specifications.api",
  ACCOUNTS_LIST = "app.accounts.list",
  ACCOUNTS_CREATE = "app.accounts.create",
  ACCOUNTS_EDIT = "app.accounts.edit",
  ACCOUNTS_DELETE = "app.accounts.delete",
}

export const USER_ROLES_PERMISSIONS = {
  [UserRoleEnum.GUEST]: [
    PermissionEnum.SERVICES_LIST,
    PermissionEnum.SERVICES_READ,
    PermissionEnum.SERVICES_SUBSCRIBE,
  ],
  [UserRoleEnum.FULL]: [
    PermissionEnum.SERVICES_LIST,
    PermissionEnum.SERVICES_READ,
    PermissionEnum.SERVICES_SUBSCRIBE,
    PermissionEnum.SERVICES_UNSUBSCRIBE,
    PermissionEnum.SERVICE_SPECIFICATIONS_DELETE,
    PermissionEnum.SERVICE_SPECIFICATIONS_CREATE,
    PermissionEnum.SERVICE_SPECIFICATIONS_EDIT,
    PermissionEnum.SERVICE_SPECIFICATIONS_LIST,
    PermissionEnum.SERVICE_SPECIFICATIONS_READ,
    PermissionEnum.SERVICE_SPECIFICATIONS_API,
    PermissionEnum.ACCOUNTS_CREATE,
    PermissionEnum.ACCOUNTS_DELETE,
    PermissionEnum.ACCOUNTS_EDIT,
    PermissionEnum.ACCOUNTS_LIST,
  ],
};
