/* tslint:disable */
/* eslint-disable */
/**
 * Apigale Private REST API
 * **Apigale Private APIs for internal use.** </br> Provided endopoints are not exposed beyond Load Balancer, and are available only to be used by other microservices. </br> These APIs are provided in Swagger, but it\'s not possible to try them from Swagger UI. 
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InlineResponse200Def
 */
export interface InlineResponse200Def {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Def
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineResponse200Def
     */
    description?: string;
}

/**
 * PrivateAccountsApi - axios parameter creator
 * @export
 */
export const PrivateAccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Queries the title and description attributes for the specified URL
         * @summary Get opengraph data for URL
         * @param {string} url The URL to collect the opengraph data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opengraphDataGet: async (url: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('opengraphDataGet', 'url', url)
            const localVarPath = `/opengraph-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PrivateAccountsApi - functional programming interface
 * @export
 */
export const PrivateAccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PrivateAccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * Queries the title and description attributes for the specified URL
         * @summary Get opengraph data for URL
         * @param {string} url The URL to collect the opengraph data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async opengraphDataGet(url: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200Def>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.opengraphDataGet(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PrivateAccountsApi - factory interface
 * @export
 */
export const PrivateAccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PrivateAccountsApiFp(configuration)
    return {
        /**
         * Queries the title and description attributes for the specified URL
         * @summary Get opengraph data for URL
         * @param {string} url The URL to collect the opengraph data from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        opengraphDataGet(url: string, options?: any): AxiosPromise<InlineResponse200Def> {
            return localVarFp.opengraphDataGet(url, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for opengraphDataGet operation in PrivateAccountsApi.
 * @export
 * @interface PrivateAccountsApiOpengraphDataGetRequest
 */
export interface PrivateAccountsApiOpengraphDataGetRequest {
    /**
     * The URL to collect the opengraph data from
     * @type {string}
     * @memberof PrivateAccountsApiOpengraphDataGet
     */
    readonly url: string
}

/**
 * PrivateAccountsApi - object-oriented interface
 * @export
 * @class PrivateAccountsApi
 * @extends {BaseAPI}
 */
export class PrivateAccountsApi extends BaseAPI {
    /**
     * Queries the title and description attributes for the specified URL
     * @summary Get opengraph data for URL
     * @param {PrivateAccountsApiOpengraphDataGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PrivateAccountsApi
     */
    public opengraphDataGet(requestParameters: PrivateAccountsApiOpengraphDataGetRequest, options?: any) {
        return PrivateAccountsApiFp(this.configuration).opengraphDataGet(requestParameters.url, options).then((request) => request(this.axios, this.basePath));
    }
}


