import { useHistory } from "react-router-dom";

import { ServiceSpecificationsRoutesEnum } from "@app/features/services-specifications/constants/service-specifications.paths";
import { serviceSpecificationsApi } from "@app/features/services-specifications/api/serviceSpecifications.api";

import useNotification from "@app/hooks/useNotification";

import { SerializeData } from "@app/utils/common";
import CreateSpecificationForm from "@app/features/services-specifications/components/CreateSpecificationForm";
import ContentLayout from "@app/components/Common/layouts/ContentLayout/ContentLayout";
import { useTranslation } from "react-i18next";
import { logger } from "@app/utils/logger";

const CreateServiceSpecification = () => {
  // const { t } = useTranslation();
  const notification = useNotification();
  const history = useHistory();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (params: any) => {
    try {
      await serviceSpecificationsApi.serviceSpecificationsPost({
        privateServiceSpecificationRequestBodyDef: SerializeData(
          "service-specifications",
          params
        ),
      });
      notification.success(
        "Successfully saved",
        "New specification was successfully saved"
      );
      history.push(ServiceSpecificationsRoutesEnum.SERVICE_SPECIFICATIONS);
    } catch (error: unknown) {
      notification.error("Error", "Specification was not be saved");
      logger.error(error);
    }
  };

  return (
    <ContentLayout
      header={{
        title: t("serviceSpecificationsCreate.title"),
      }}
      breadcrumbs={[
        {
          path: ServiceSpecificationsRoutesEnum.SERVICE_SPECIFICATIONS,
          breadcrumbName: t("serviceSpecifications.title"),
        },
        {
          breadcrumbName: t("serviceSpecifications.create"),
        },
      ]}
    >
      <CreateSpecificationForm
        onSubmit={handleSubmit}
        initialValues={{ title: "", name: "" }}
      />
    </ContentLayout>
  );
};

export default CreateServiceSpecification;
